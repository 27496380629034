import { Box } from '@mui/material';
import Header from 'src/widgets/Header';
import Logo from 'src/components/Logo';

import useIsMobileSize from 'src/hooks/useIsMobileSize';

import useStyles from './layout.styles';

import type { ReactNode } from 'react';

type LayoutProps = {
    children?: ReactNode;
    isWhite?: boolean;
};

export default function Layout({ children, isWhite = false }: LayoutProps) {
    const classes = useStyles();
    const isMobile = useIsMobileSize();

    const renderChildren = () => {
        if (!isMobile) {
            return (
                <Box
                    margin="0 auto"
                    maxWidth={370}
                >
                    {children}
                </Box>
            );
        }

        return <>{children}</>;
    };

    return (
        <Box className={classes.root}>
            {isMobile && <Header white={isWhite} />}
            <Box
                className={classes.container}
                py={{ lg: 0, md: 0, sm: 0.5, xs: 0.5 }}
                px={{ lg: 0, md: 0, sm: 1.5, xs: 1.5 }}
                minHeight={'calc(100dvh - 48px)'}
            >
                {!isMobile && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        mb={2}
                    >
                        <Logo mr={-200 / 8} />
                    </Box>
                )}
                {renderChildren()}
            </Box>
        </Box>
    );
}
