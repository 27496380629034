import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import OtpField from 'src/components/OtpField';
import SendCodeAgain from 'src/widgets/SendCodeAgain';
import usePasswordRestoreStore from 'src/stores/restorePassword';
import useGoTo from 'src/hooks/useGoTo';
import ROUTES from 'src/router/routes';
import { enqueueSnackbar } from 'notistack';

type RestorePasswordFormType = {
    code: string;
};

const RestorePasswordForm = () => {
    const ref = useRef<HTMLButtonElement | null>(null);
    const goTo = useGoTo();
    const passwordRestoreStore = usePasswordRestoreStore();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<RestorePasswordFormType>({
        defaultValues: {
            code: '',
        },
    });

    const onSubmit: SubmitHandler<RestorePasswordFormType> = (data) => {
        passwordRestoreStore.setCode(data.code);

        goTo(ROUTES.CREATE_PASSWORD.path);
    };

    const handleComplete = () => {
        if (ref.current) ref.current.click();
    };

    const handleRefetchCode = async () => {
        try {
            await passwordRestoreStore.restorePassword();
            enqueueSnackbar('Код отправлен на вашу почту', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Ошибка отправки почты', { variant: 'error' });
            console.info(error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                mb={2}
                width="80%"
            >
                <Typography
                    variant="body1"
                    color="primary"
                >
                    Код отправлен на вашу почту {passwordRestoreStore.email}
                </Typography>
            </Box>
            <Box mb={2}>
                <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                        <OtpField
                            {...field}
                            error={errors?.code?.message}
                            onComplete={handleComplete}
                        />
                    )}
                />
            </Box>
            <Box mb={0.5}>
                <SendCodeAgain
                    timer={30}
                    onClick={handleRefetchCode}
                />
            </Box>
            <Box display="none">
                <button
                    type="submit"
                    ref={(r) => (ref.current = r)}
                ></button>
            </Box>
        </form>
    );
};

export default RestorePasswordForm;
