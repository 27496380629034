export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.70312 7.5L2.70312 9.5L0.703125 7.5"
            stroke="currentColor "
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.68387 9.41551C2.56392 8.96387 2.5 8.4894 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C7.02399 13.5 6.10735 13.2458 5.3127 12.7999"
            stroke="currentColor"
        />
    </svg>
);
