import axios, { isAxiosError, isCancel, type CancelTokenSource } from 'axios';
import LOCAL_STORAGE_KEY from 'src/constants/localKeys';
import { removeHeaderFromDefaults, setDefaultHeaders } from 'src/agent';

import UserSevice from '../UserSevice';
import SubscriptionsService from '../SubscriptionsService';

import CollectionService from '../CollectionService';

import ProjectsService from '../ProjectsService';

import { signIn, signUp, updateToken, getCode, confirmResetPassword, confirmEmail, refreshEmailCode } from './agent';

import type { IUserSignIn, IUserRegister } from 'src/types/User';

function configureTokenHeader(token: string) {
    if (token) {
        setDefaultHeaders('Authorization', `Bearer ${token}`);
        return;
    }
    removeHeaderFromDefaults('Authorization');
}

class SessionService {
    queryKey = 'session';

    cancelToken: CancelTokenSource | undefined;

    constructor() {
        this.accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || '';
        this.refreshTokenValue = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN) || '';
    }

    private accessTokenValue = '';

    private refreshTokenValue = '';

    get accessToken() {
        if (this.accessTokenValue) {
            return this.accessTokenValue;
        }
        this.accessTokenValue = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || '';
        return this.accessTokenValue;
    }

    set accessToken(token: string) {
        this.accessTokenValue = token;
        configureTokenHeader(token);
        localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, token);
    }

    get refreshToken() {
        if (this.refreshTokenValue) {
            return this.refreshTokenValue;
        }
        this.refreshTokenValue = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN) || '';
        return this.refreshTokenValue;
    }

    set refreshToken(token: string) {
        this.refreshTokenValue = token;
        localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, token);
    }

    signup = async (values: IUserRegister) => {
        try {
            const { data } = await signUp(values);

            return Promise.resolve(data);
        } catch (error) {
            if (isAxiosError(error)) {
                const data: Record<string, unknown> = error.response?.data;
                return Promise.reject(data);
            }
            return Promise.reject(error);
        }
    };

    getUserSessionRequiredData = async () => {
        try {
            const user = await UserSevice.getUser();
            const subscriptions = await SubscriptionsService.getSubscriptionsList();
            const collections = await CollectionService.getAllCollections();
            const projects = await ProjectsService.getProjects();

            return Promise.resolve({ user, subscriptions, collections, projects } as const);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    signin = async (values: IUserSignIn) => {
        try {
            const {
                data: { access: accessToken, refresh: refreshToken },
                data,
            } = await signIn(values);
            if (accessToken) {
                this.accessToken = accessToken;
                this.refreshToken = refreshToken;
            }
            return Promise.resolve({ ...data });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const data = error.response?.data;
                return Promise.reject(data);
            }
            return Promise.reject(error);
        }
    };

    signOut = () => {
        this.accessToken = '';
        this.refreshToken = '';

        localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    };

    updateToken = async () => {
        try {
            const refreshToken = this.refreshToken;
            if (refreshToken) {
                const {
                    data: { access: accessToken },
                    data,
                } = await updateToken(refreshToken);
                this.accessToken = accessToken;
                return Promise.resolve({ ...data });
            }
            throw new Error('Рефреш токена нет');
        } catch (error) {
            this.signOut();
            return Promise.reject(error);
        }
    };

    resetPassword = async (values: Pick<IUserSignIn, 'email'>) => {
        try {
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }

            this.cancelToken = axios.CancelToken.source();

            await getCode(values, this.cancelToken.token);

            return Promise.resolve({});
        } catch (error) {
            if (isCancel(error)) {
                return;
            }
            console.log(error);
            return Promise.reject(error);
        }
    };

    confirmResetPassword = async (
        values: { code: string } & Pick<IUserRegister, 'email' | 'password' | 'password2'>
    ) => {
        try {
            await confirmResetPassword({
                ...values,
                new_password: values.password,
                new_password2: values.password2,
            });

            return Promise.resolve({});
        } catch (error) {
            console.log(error);

            return Promise.reject(error);
        }
    };

    confirmEmail = async (values: { code: string } & Pick<IUserRegister, 'email'>) => {
        try {
            await confirmEmail(values);

            return Promise.resolve({});
        } catch (error) {
            console.log(error);

            return Promise.reject(error);
        }
    };

    refetchEmailCode = async () => {
        try {
            await refreshEmailCode();
            return Promise.resolve();
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    };
}

export default new SessionService();
