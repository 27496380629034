import { useState, ReactNode } from 'react';
import { Box, Collapse } from '@mui/material';

import ButtonText from 'src/components/ButtonText';

type CustomAccordionProps = {
    title: string;
    children: ReactNode;
    [T: string]: any;
};

const CustomAccordion = ({ title = '', children, ...otherProps }: CustomAccordionProps) => {
    const [expanded, setExpand] = useState(false);
    const handleClick = () => {
        setExpand((prev) => !prev);
    };

    return (
        <Box>
            <Box {...otherProps}>
                <ButtonText
                    icon={expanded ? 'CHEVRONE_UP' : 'INFO'}
                    onClick={handleClick}
                >
                    {title}
                </ButtonText>
            </Box>
            <Collapse in={expanded}>
                <Box pt={1}>{children}</Box>
            </Collapse>
        </Box>
    );
};

export default CustomAccordion;
