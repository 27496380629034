export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <path
                d="M5.56572 5.63918C6.09352 5.03598 6.35742 4.73438 6.67636 4.51787C6.959 4.326 7.27234 4.18382 7.60286 4.09746C7.97582 4 8.37658 4 9.17809 4H18.2C19.8802 4 20.7202 4 21.362 4.32698C21.9265 4.6146 22.3854 5.07354 22.673 5.63803C23 6.27976 23 7.11984 23 8.8V15.2C23 16.8802 23 17.7202 22.673 18.362C22.3854 18.9265 21.9265 19.3854 21.362 19.673C20.7202 20 19.8802 20 18.2 20H9.17809C8.37658 20 7.97582 20 7.60286 19.9025C7.27234 19.8162 6.959 19.674 6.67636 19.4821C6.35742 19.2656 6.09352 18.964 5.56572 18.3608L2.76572 15.1608C1.78579 14.0409 1.29583 13.4809 1.10946 12.8508C0.945251 12.2955 0.945251 11.7045 1.10946 11.1492C1.29583 10.5191 1.78579 9.9591 2.76572 8.83918L5.56572 5.63918Z"
                fill="#E3ECF3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3536 7.64645C10.1583 7.45118 9.84171 7.45118 9.64645 7.64645C9.45118 7.84171 9.45118 8.15829 9.64645 8.35355L13.2929 12L9.64645 15.6464C9.45118 15.8417 9.45118 16.1583 9.64645 16.3536C9.84171 16.5488 10.1583 16.5488 10.3536 16.3536L14 12.7071L17.6464 16.3536C17.8417 16.5488 18.1583 16.5488 18.3536 16.3536C18.5488 16.1583 18.5488 15.8417 18.3536 15.6464L14.7071 12L18.3536 8.35355C18.5488 8.15829 18.5488 7.84171 18.3536 7.64645C18.1583 7.45118 17.8417 7.45118 17.6464 7.64645L14 11.2929L10.3536 7.64645Z"
                fill="black"
            />
        </g>
    </svg>
);
