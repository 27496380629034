import { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useForm, FormProvider, Controller, useFormContext } from 'react-hook-form';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import CheckboxGroup from 'src/components/CheckboxGroup';

import { closeBottomSheet } from 'src/components/BottomSheet';

import type { Subscription } from 'react-hook-form/dist/utils/createSubject';

type SelectEntityFormProps = {
    name: string;
    type?: 'radio' | 'checkbox';
    buttonTitle?: string;
    defaultValues?: Obj;
    options: Array<{ id: number | string; name: string }>;
    withSubmitButton?: boolean;
    withClearButton?: boolean;
    allSelectedText?: string;
    maxCount?: number | null;
    onSubmit?: (data: unknown) => any;
    withCustomForm?: boolean;
};

type TSelectEntityFormContentProps = Omit<SelectEntityFormProps, 'onSubmit' | 'withCustomFormMethods'>;

const SelectEntityFormContent = ({
    type,
    name,
    options,
    buttonTitle = 'Применить',
    withSubmitButton = true,
    withClearButton = true,
    allSelectedText,
    maxCount,
    onSubmit,
}: TSelectEntityFormContentProps & { onSubmit: (data: unknown) => void }) => {
    const methods = useFormContext();
    const isMobile = useIsMobileSize();

    const { watch } = methods;

    useEffect(() => {
        let subscription: Subscription | null = null;
        if (!isMobile) {
            subscription = watch(() => {
                return methods.handleSubmit(onSubmit)();
            });
        }

        return () => {
            subscription?.unsubscribe();
        };
    }, [isMobile]);

    return (
        <>
            <Box mb={1}>
                <Controller
                    name={name}
                    control={methods.control}
                    render={({ field }) => {
                        return (
                            <CheckboxGroup
                                type={type}
                                field={field}
                                options={options}
                                withClearButton={withClearButton}
                                allSelectedText={allSelectedText}
                                maxCount={maxCount}
                            />
                        );
                    }}
                />
            </Box>
            {withSubmitButton && (
                <Box py={2}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        {buttonTitle}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default function SelectEntityForm(props: SelectEntityFormProps) {
    const { withCustomForm, defaultValues, onSubmit } = props;

    const isMobile = useIsMobileSize();

    const methods = useForm({
        defaultValues: defaultValues || {},
    });

    useEffect(() => {
        methods.reset(defaultValues);
    }, [JSON.stringify(defaultValues)]);

    const handleSumbit = (data: unknown) => {
        onSubmit?.(data);

        if (isMobile) {
            closeBottomSheet();
        }
    };

    return withCustomForm ? (
        <SelectEntityFormContent
            {...props}
            onSubmit={handleSumbit}
        />
    ) : (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSumbit)}>
                <SelectEntityFormContent
                    {...props}
                    onSubmit={handleSumbit}
                />
            </form>
        </FormProvider>
    );
}
