import { Box, Button, Typography } from '@mui/material';
import CenterScreen from 'src/components/CenterScreen';
import useGoTo from 'src/hooks/useGoTo';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

export interface IFinishCreateProjectProps {
    onSuccess?: () => void;
}

export default function FinishCreateProjectContent({ onSuccess }: IFinishCreateProjectProps) {
    const isMobile = useIsMobileSize();
    const goTo = useGoTo();

    const onNextClickHandler = () => {
        onSuccess?.();
        goTo('/');
    };

    return (
        <Box
            bgcolor="background.paper"
            position={isMobile ? 'absolute' : 'static'}
            height={'100dvh'}
            sx={{ inset: '0 2rem' }}
        >
            <Box
                position="relative"
                display="flex"
                flexDirection="column"
                height={isMobile ? '100dvh' : 'auto'}
                gap={3}
            >
                <CenterScreen>
                    <Box
                        maxHeight={240}
                        overflow="hidden"
                    >
                        <Box>
                            <Box mb={5}>
                                <Typography
                                    textAlign="center"
                                    variant="h1"
                                    color="primary"
                                >
                                    Сервис успешно
                                    <br />
                                    запущен
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    textAlign="center"
                                    variant="body1"
                                    color="primary"
                                >
                                    Поздравляем! В ближайшее время сервис добавится в нужные чаты и запустит
                                    круглосуточный мониторинг целевых сообщений. Вы увидите новые сообщения в ленте
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CenterScreen>
                <Box
                    display="flex"
                    alignItems="flex-end"
                    pb={10}
                    flexGrow={3}
                    width={230}
                    mx={'auto'}
                >
                    <Button
                        onClick={onNextClickHandler}
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Далее
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
