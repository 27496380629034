import LoginScreen from 'src/screens/Login';
import NotSignalScreen from 'src/screens/NotSignal';
import WelcomeScreen from 'src/screens/WelcomeScreen';
import ScaningChannelsScreen from 'src/screens/ScaningChannels';
import RegisterScreen from 'src/screens/Register';
// import EnterPasswordScene from 'src/screens/EnterPassword'
import RestorePasswordScene from 'src/screens/RestorePassword';
import CreatePasswordScene from 'src/screens/CreatePassword';
import BeginWorkScreen from 'src/screens/BeginWork';
import SubscriptionsScene from 'src/screens/Subscriptions';
import PaymentScreen from 'src/screens/Payment';
import KeywordsScreen from 'src/screens/Keywords';
import CollectionScreen from 'src/screens/Collections';
import CreateProjectScreen from 'src/screens/CreateProject';
import FinishCreateProjectScreen from 'src/screens/CreateProject/FinishCreateProject';
import ChangePasswordScreen from 'src/screens/ChangePassword';
import FeedScreen from 'src/screens/Feed';
import MyTariffScene from 'src/screens/MyTariff';
import SelectChatsAndChannelsScreen from 'src/screens/CreateProject/SelectChatsAndChannels';
import SelectCollectionsScreen from 'src/screens/CreateProject/SelectColletions';
import ChatsAndChannelsScreen from 'src/screens/ChatsAndChannels';
import BeginWorkKeywords from 'src/screens/BeginWork/Keywords';
import { ROUTES_URL } from 'src/constants/routes';
import BeginWorkCollections from 'src/screens/BeginWork/Collections';
import BeginWorkChatsAndChannels from 'src/screens/BeginWork/ChatsAndChannels';
import BeginWorkSubscriptions from 'src/screens/BeginWork/Subscriptions';
import ChangeEmailScreen from 'src/screens/ChangeEmail';
import BeginWorkCreateProject from 'src/screens/BeginWork/CreateProject';
import BeginWorkFinishProject from 'src/screens/BeginWork/FinishProject';
import NotFound404 from 'src/screens/NotFound404';
import DescribeYourBusiness from 'src/screens/BeginWork/DescribeYourBusiness';

import type { TRoutesKeys, TRoutesPath } from 'src/constants/routes';

type RouteInfo = {
    path: TRoutesPath;
    component: any;
    isPublic: boolean;
    exact: boolean;
};

export type RouteName = TRoutesKeys;

type RoutesType = Record<TRoutesKeys, RouteInfo>;

const ROUTES: RoutesType = {
    LOGIN: {
        path: ROUTES_URL.LOGIN,
        component: LoginScreen,
        isPublic: true,
        exact: true,
    },
    REGISTER: {
        path: ROUTES_URL.REGISTER,
        component: RegisterScreen,
        isPublic: true,
        exact: true,
    },
    // ENTER_PASSWORD: {
    //     path: '/password',
    //     component: EnterPasswordScene,
    //     isPublic: true,
    //     exact: true,
    // },
    RESTORE_PASSWORD: {
        path: ROUTES_URL.RESTORE_PASSWORD,
        component: RestorePasswordScene,
        isPublic: true,
        exact: true,
    },
    CREATE_PASSWORD: {
        path: ROUTES_URL.CREATE_PASSWORD,
        component: CreatePasswordScene,
        isPublic: true,
        exact: true,
    },
    CHANGE_PASSWORD: {
        path: ROUTES_URL.CHANGE_PASSWORD,
        component: ChangePasswordScreen,
        isPublic: false,
        exact: true,
    },
    CHANGE_EMAIL: {
        path: ROUTES_URL.CHANGE_EMAIL,
        component: ChangeEmailScreen,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK: {
        path: ROUTES_URL.BEGIN_WORK,
        component: BeginWorkScreen,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_SUBSCRIPTIONS: {
        path: ROUTES_URL.BEGIN_WORK_SUBSCRIPTIONS,
        component: BeginWorkSubscriptions,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_DESCRIPTION: {
        path: ROUTES_URL.BEGIN_WORK_DESCRIPTION,
        component: DescribeYourBusiness,
        // isPublic: false,
        isPublic: true,
        exact: true,
    },
    BEGIN_WORK_COLLECTIONS: {
        path: ROUTES_URL.BEGIN_WORK_COLLECTIONS,
        component: BeginWorkCollections,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_CHATS_AND_CHANNELS: {
        path: ROUTES_URL.BEGIN_WORK_CHATS_AND_CHANNELS,
        component: BeginWorkChatsAndChannels,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_KEYWORDS: {
        path: ROUTES_URL.BEGIN_WORK_KEYWORDS,
        component: BeginWorkKeywords,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_CREATE_PROJECT: {
        path: ROUTES_URL.BEGIN_WORK_CREATE_PROJECT,
        component: BeginWorkCreateProject,
        isPublic: false,
        exact: true,
    },
    BEGIN_WORK_CREATE_PROJECT_FINISH: {
        path: ROUTES_URL.BEGIN_WORK_CREATE_PROJECT_FINISH,
        component: BeginWorkFinishProject,
        isPublic: false,
        exact: true,
    },
    NO_SIGNAL: {
        path: ROUTES_URL.NO_SIGNAL,
        component: NotSignalScreen,
        isPublic: true,
        exact: true,
    },
    WELCOME_SCREEN: {
        path: ROUTES_URL.WELCOME_SCREEN,
        component: WelcomeScreen,
        isPublic: false,
        exact: true,
    },
    SCANING_CHANNELS: {
        path: ROUTES_URL.SCANING_CHANNELS,
        component: ScaningChannelsScreen,
        isPublic: false,
        exact: true,
    },
    SUBSCRIPTIONS: {
        path: ROUTES_URL.SUBSCRIPTIONS,
        component: SubscriptionsScene,
        isPublic: false,
        exact: true,
    },
    PAYMENT: {
        path: ROUTES_URL.PAYMENT,
        component: PaymentScreen,
        isPublic: false,
        exact: true,
    },
    CHATS_AND_CHANNELS: {
        path: ROUTES_URL.CHATS_AND_CHANNELS,
        component: ChatsAndChannelsScreen,
        isPublic: false,
        exact: true,
    },
    KEYWORDS: {
        path: ROUTES_URL.KEYWORDS,
        component: KeywordsScreen,
        isPublic: false,
        exact: true,
    },
    COLLECTIONS: {
        path: ROUTES_URL.COLLECTIONS,
        component: CollectionScreen,
        isPublic: false,
        exact: true,
    },
    CREATE_PROJECT: {
        path: ROUTES_URL.CREATE_PROJECT,
        component: CreateProjectScreen,
        isPublic: false,
        exact: true,
    },
    CREATE_PROJECT_SELECT_CHANNELS: {
        path: ROUTES_URL.CREATE_PROJECT_SELECT_CHANNELS,
        component: SelectChatsAndChannelsScreen,
        isPublic: false,
        exact: true,
    },
    CREATE_PROJECT_SELECT_COLLECTIONS: {
        path: ROUTES_URL.CREATE_PROJECT_SELECT_COLLECTIONS,
        component: SelectCollectionsScreen,
        isPublic: false,
        exact: true,
    },
    CREATE_PROJECT_FINISH: {
        path: ROUTES_URL.CREATE_PROJECT_FINISH,
        component: FinishCreateProjectScreen,
        isPublic: false,
        exact: true,
    },
    FEED: {
        path: ROUTES_URL.FEED,
        component: FeedScreen,
        isPublic: false,
        exact: true,
    },
    MY_TARIFF: {
        path: ROUTES_URL.MY_TARIFF,
        component: MyTariffScene,
        isPublic: false,
        exact: true,
    },
    FAVORITES: {
        path: ROUTES_URL.FAVORITES,
        component: FeedScreen,
        isPublic: false,
        exact: true,
    },
    NOT_FOUND: {
        path: ROUTES_URL.NOT_FOUND,
        component: NotFound404,
        isPublic: true,
        exact: false,
    },
};

export default ROUTES;
