export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 3C10.2337 3 12 4.75792 12 6.87231C12 7.73852 11.6573 8.67968 11.0589 9.68657C10.4636 10.6883 9.66709 11.671 8.86004 12.6162C8.42581 13.1248 7.57419 13.1248 7.13996 12.6162C6.33291 11.671 5.53637 10.6883 4.94107 9.68657C4.34272 8.67968 4 7.73852 4 6.87231C4 4.75792 5.76632 3 8 3ZM13 6.87231C13 4.18141 10.7614 2 8 2C5.23858 2 3 4.18141 3 6.87231C3 9.11487 4.75724 11.3656 6.37946 13.2655C7.2129 14.2416 8.7871 14.2416 9.62054 13.2655C11.2428 11.3656 13 9.11487 13 6.87231ZM8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6Z"
            fill="#1F1F1F"
        />
    </svg>
);
