export default () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    d="M20 12L4.5 12"
                    stroke="black"
                />
                <path
                    d="M10.5 18L5.20711 12.7071C4.81658 12.3166 4.81658 11.6834 5.20711 11.2929L10.5 6"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
