import { create } from 'zustand';
import KeywordsService from 'src/services/KeywordsService';

import type { IKeyword } from 'src/types/Keywords';

type KeywordsStore = {
    keywords: IKeyword[];
    setKeywords: (chats: IKeyword[]) => void;
    fetchKeywords: () => Promise<void>;
};

const useKeywordStore = create<KeywordsStore>((set) => ({
    keywords: [],

    setKeywords: (keywords) => set({ keywords }),

    fetchKeywords: async () => {
        try {
            const keywords = await KeywordsService.fetch();

            set({ keywords });
            return Promise.resolve();
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    },
}));

export default useKeywordStore;
