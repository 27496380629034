import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import SessionService from 'src/services/SessionService';
import UserStore from 'src/stores/user';
import SubscriptionsStore from 'src/stores/subscriptions';
import ProjectsStore from './projects';
import CollectionsStore from './collections';
import ChatsStore from './chats';
import KeywordsStore from './keywords';
import MessagesStore from './messages';
import FilterStore from './filter';

import type { IUserSignIn, IUserRegister } from 'src/types/User';

interface IState {
    error: string | null;
    isAuthorized: boolean;
}

interface IAction {
    setIsAuthorized: (value: boolean) => void;

    register: (values: IUserRegister) => Promise<any>;

    login: (values?: IUserSignIn) => Promise<any>;
    logout: () => void;

    getUserSessionRequiredData: typeof SessionService.getUserSessionRequiredData;
}

const useSessionStore = create<IState & IAction>(
    (set, get) => ({
        error: null,

        isAuthorized: false,
        setIsAuthorized: (value) => {
            set({ isAuthorized: value });
        },

        getUserSessionRequiredData: async () => {
            try {
                const data = await SessionService.getUserSessionRequiredData();
                const { user, subscriptions, projects, collections } = data;

                UserStore.getState().setUserInfo(user);
                SubscriptionsStore.getState().setSubscriptions(subscriptions);
                ProjectsStore.getState().setProjects(projects);
                CollectionsStore.getState().setCollections(collections);

                set({
                    isAuthorized: true,
                });

                return Promise.resolve(data);
            } catch (err) {
                set({
                    isAuthorized: false,
                });

                return Promise.reject(err);
            }
        },

        login: async (values) => {
            if (!values) {
                return;
            }

            try {
                await SessionService.signin(values);

                await get().getUserSessionRequiredData();

                set({
                    isAuthorized: true,
                });

                return Promise.resolve();
            } catch (error) {
                set({
                    isAuthorized: false,
                    error: '',
                });

                return Promise.reject(error);
            }
        },

        register: async (values) => {
            try {
                await SessionService.signup(values);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        logout: () => {
            SessionService.signOut();
            UserStore.getState().clearUser();
            ChatsStore.getState().setChats([]);
            SubscriptionsStore.getState().clear();
            ProjectsStore.getState().clear();
            CollectionsStore.getState().clear();
            KeywordsStore.getState().setKeywords([]);
            MessagesStore.getState().clear();
            FilterStore.getState().clearFilter();

            set({
                isAuthorized: false,
                error: null,
            });
        },
    }),
    shallow
);

export default useSessionStore;
