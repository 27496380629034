export default class ForbiddenError extends Error {
    name = 'ForbiddenError';

    constructor(message: string | undefined, cause?: unknown) {
        super(message);

        this.cause = cause;

        if (cause) {
            this.stack = (cause as { stack: unknown })?.stack as string;
        }
    }
}
