export const CaseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.5"
                y="6.5"
                width="19"
                height="15"
                rx="2.5"
                stroke="#1F1F1F"
            />
            <path
                d="M2.5 9C2.5 7.61929 3.61929 6.5 5 6.5H19C20.3807 6.5 21.5 7.61929 21.5 9V11C21.5 12.3807 20.3807 13.5 19 13.5H12H5C3.61929 13.5 2.5 12.3807 2.5 11V9Z"
                stroke="#1F1F1F"
            />
            <rect
                x="10"
                y="13"
                width="4"
                height="3"
                rx="1"
                fill="#1F1F1F"
            />
            <path
                d="M8.5 6.5V4.5C8.5 3.39543 9.39543 2.5 10.5 2.5H13.5C14.6046 2.5 15.5 3.39543 15.5 4.5V6.5"
                stroke="#1F1F1F"
            />
        </svg>
    );
};
