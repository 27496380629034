import {
    Box,
    Button,
    Card,
    CardContent,
    CardActions,
    // Collapse,
    Menu,
    MenuItem,
    Divider,
    Avatar,
    IconButton,
    Typography,
    styled,
    Stack,
} from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import useMessagesStore from 'src/stores/messages';
import { useClipboard } from 'src/hooks/useClipboard';
import ArrowUpRightInCircle from 'src/icons/ArrowUpRightInCircle';
import More from 'src/icons/More';
import Bookmark from 'src/icons/Bookmark';
import Copy from 'src/icons/Copy';
import Remove from 'src/icons/Remove';

// import CollapseIcon from 'src/icons/CollapseIcon';
import { ROUTES_URL } from 'src/constants/routes';

import { getTgNickNameByLink } from '../utils';

import type { MouseEvent } from 'react';
import type { IMessage } from 'src/types/Message';
import { AIIcon } from 'src/icons/AIIcon';

type Props = {
    body: IMessage;
};

const MessageBox = styled(Box)(() => ({
    padding: '4px 8px',
    borderRadius: '8px',
    boxShadow: '0px 2px 10px 0px rgba(68, 73, 103, 0.10)',
}));

const TimeBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 30,
    flexShrink: 0,
    flexGrow: 0,
}));

const CardHeader = styled(Box)(() => ({}));

export default ({ body }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { pathname } = useLocation();
    const { copy } = useClipboard();
    const open = Boolean(anchorEl);
    const isFavoriteOnly = pathname === ROUTES_URL.FAVORITES;

    const { isLoading, mutateAsync } = useMutation(useMessagesStore.getState().toggleFavorite, {
        onError: (error) => {
            console.log(error);
            enqueueSnackbar({ message: 'Произошла ошибка. Попробуйте повторить позже', variant: 'error' });
        },
    });

    const { isLoading: isLoadingRemove, mutateAsync: removeMessage } = useMutation(
        useMessagesStore.getState().removeMessage,
        {
            onError: (error) => {
                console.log(error);

                enqueueSnackbar({
                    message: 'Неудалось удалить сообщение. Попробуйте повторить позже',
                    variant: 'error',
                });
            },
        }
    );

    const setFavoriteStatus = () => {
        mutateAsync({ id: body.id, flag: !body.favorite });

        if (!!body.favorite && isFavoriteOnly) {
            useMessagesStore.getState().deleteFromFavorite(body.id);
        }
    };

    const handleClickCardMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFavoriteClick = () => {
        if (!isLoading) setFavoriteStatus();
    };

    const handleRemove = () => {
        if (!isLoadingRemove) removeMessage(body.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderContextMenuCard = () => {
        return (
            <Menu
                anchorEl={anchorEl}
                id="card-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                //disable lock for window
                //disableScrollLock
                // marginThreshold={null}
            >
                <Box
                    width={240}
                    sx={{
                        background: '#EBF0F2',
                    }}
                    mt={-1}
                    p={0.5}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width={1}
                        height={54}
                        p={1}
                        borderRadius={3}
                        onClick={handleFavoriteClick}
                        style={{
                            cursor: 'pointer',
                        }}
                        bgcolor="background.default"
                    >
                        <Bookmark fill={body.favorite} />
                        <Typography variant="overline">Добавить в избранное</Typography>
                    </Box>
                </Box>
                <MenuItem
                    onClick={() => {
                        copy(body.link);
                    }}
                >
                    Скопировать ссылку <Copy />
                </MenuItem>
                <Divider />
                <MenuItem
                    disabled={isLoadingRemove}
                    onClick={handleRemove}
                >
                    Удалить сообщение <Remove />
                </MenuItem>
            </Menu>
        );
    };
    const renderTitle = () => {
        return (
            <Box
                display="flex"
                alignItems="center"
                gap={1}
                maxWidth={'80%'}
                overflow="hidden"
            >
                <Typography
                    variant="body2"
                    noWrap
                    title={body.chat_name}
                >
                    {body.chat_name}
                </Typography>
                {body.chat_name && (
                    <Typography
                        variant="body2"
                        color="textDisabled"
                    >
                        от
                    </Typography>
                )}
                <Typography
                    variant="body2"
                    noWrap
                    title={body.author}
                >
                    {getTgNickNameByLink(body.author)}
                </Typography>
            </Box>
        );
    };

    const renderMessage = () => {
        return (
            <>
                <Box
                    display="flex"
                    gap={1}
                    paddingRight={1}
                >
                    {body.is_lead && (
                        <Box
                            display="block"
                            width={1}
                            sx={{
                                borderRadius: '8px',
                                boxShadow:
                                    ' 0px -2px 6px 0px rgba(0, 163, 255, 0.20), 0px 2px 6px 0px rgba(250, 0, 255, 0.10);',
                            }}
                        >
                            <MessageBox>
                                <Typography>{body.content}</Typography>
                            </MessageBox>
                            {/* тут будет раскрывашка  */}
                        </Box>
                    )}

                    {!body.is_lead && (
                        <Box
                            display="block"
                            width={1}
                            sx={{
                                borderRadius: '8px',
                            }}
                        >
                            <MessageBox>
                                <Typography>{body.content}</Typography>
                            </MessageBox>
                            {/* тут будет раскрывашка  */}
                        </Box>
                    )}

                    <TimeBox>
                        <Typography
                            variant="overline"
                            color="textDisabled"
                        >
                            {dayjs(body.date).format('HH:mm')}
                        </Typography>
                        {/* <IconButton size="small">
                        <CollapseIcon />
                    </IconButton> */}
                    </TimeBox>
                </Box>

                {body.is_lead && (
                    <Stack
                        gap="2px"
                        direction="row"
                        alignItems="center"
                        sx={{ mt: 0.5 }}
                    >
                        <AIIcon />
                        <Typography
                            variant="body2"
                            sx={{
                                background: 'linear-gradient(90deg, #FF88C0, #9FA9BC)',
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                            }}
                        >
                            Целевое сообщение
                        </Typography>
                    </Stack>
                )}
            </>
        );
    };
    return (
        <LoadingWrapper
            isLoading={isLoadingRemove}
            isLocal
        >
            {renderContextMenuCard()}
            <Card variant="outlined">
                <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="space-between"
                    px={1}
                >
                    {renderTitle()}
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography
                            variant="body2"
                            color="textDisabled"
                            flex={1}
                            noWrap
                        >
                            {dayjs(body.date).fromNow(true)}
                        </Typography>

                        <IconButton
                            sx={{ width: '40px' }}
                            onClick={handleClickCardMenu}
                        >
                            <More />
                        </IconButton>
                    </Box>
                </Box>
                <CardContent sx={{ width: '100%' }}>{renderMessage()}</CardContent>
                <CardActions
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    disableSpacing
                >
                    <Button
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        sx={{
                            fontSize: '14px',
                            border: '1px solid #E3ECF3',
                            borderRadius: '8px',
                        }}
                        startIcon={<ArrowUpRightInCircle />}
                        onClick={() => {
                            window.open(body.author, '_blank');
                        }}
                    >
                        Написать
                    </Button>
                    <Box>
                        <IconButton
                            size="medium"
                            onClick={handleFavoriteClick}
                            disabled={isLoading}
                            sx={{
                                padding: 0.5,
                            }}
                        >
                            <Bookmark fill={body.favorite} />
                        </IconButton>
                    </Box>
                </CardActions>
            </Card>
        </LoadingWrapper>
    );
};
