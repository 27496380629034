import { object, string, TypeOf } from 'zod';

const RegisterScheme = object({
    email: string().min(1, 'Email обязательное поле').email('Поле Email не валидно'),
    password: string().min(1, 'Поле пароля обязательное'),
    password2: string().min(1, 'Поле подтверждения пароля обязательное'),
}).refine((data) => data.password === data.password2, {
    path: ['password2'],
    message: 'Пароли не совпадают',
});

export type TypeRegisterScheme = TypeOf<typeof RegisterScheme>;

export default RegisterScheme;
