import { Button, styled } from '@mui/material';

const TransparentButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: '1px solid #E3ECF3',
    boxShadow: 'none',
}));

export default TransparentButton;
