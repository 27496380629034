import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise } from 'axios';
import type { IKeyword } from 'src/types/Keywords';


export const fetchKeywords = ():AxiosPromise<IKeyword[]> => {
    return globalAgent.get(URLS.KEYWORDS);
};
