export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 15.4183 15.4183 19 11 19ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.1259 19.2629 15.0796 18.0304 16.6196L21.7037 20.2929C22.0942 20.6834 22.0942 21.3166 21.7037 21.7071C21.3132 22.0976 20.68 22.0976 20.2895 21.7071L16.6158 18.0334C15.0764 19.2641 13.1241 20 11 20C6.02944 20 2 15.9706 2 11Z"
                fill="#1F1F1F"
            />
        </g>
    </svg>
);
