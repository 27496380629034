import { Box } from '@mui/material';
import useKeywordsStore from 'src/stores/keywords';

import useIsMobileSize from 'src/hooks/useIsMobileSize';

import BottomSheetHeader from '../BottomSheetHeader';
import { getKeywordsFilter } from '../FeedFilter/utils';
import FilterItem from '../FeedFilter/Components/FilterItem';

export default function KeywordsSheet() {
    const { keywords } = useKeywordsStore(({ keywords }) => ({ keywords }));

    const isMobile = useIsMobileSize();

    const item = getKeywordsFilter(keywords, isMobile);

    return (
        <Box px={1}>
            <BottomSheetHeader title="Ключевые слова" />
            {
                <FilterItem
                    key={item.title}
                    {...item}
                />
            }
        </Box>
    );
}
