import { createTheme } from '@mui/material';

import typography from './typography';
import palette from './palette';

const height = {
    xl: 48,
    lg: 40,
    md: 32,
    sm: 24,
};

const borderRadius = {
    xl: 16,
    lg: 12,
    md: 8,
    sm: '50%',
};

export default createTheme({
    typography,
    palette,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                boxSizing: 'border-box',
                fontFamily: '"Inter", system-ui',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter',
                    fontSize: 16,
                    fontWeight: 400,
                    textTransform: 'none',
                    boxShadow: 'none',
                },
                sizeLarge: {
                    height: height.xl,
                },
                sizeMedium: {
                    height: height.md,
                },
                sizeSmall: {
                    height: height.sm,
                },
                containedSizeLarge: {
                    borderRadius: borderRadius.xl,
                },
                containedSizeMedium: {
                    borderRadius: borderRadius.md,
                },
                containedSizeSmall: {
                    borderRadius: borderRadius.sm,
                },
                contained: {
                    color: '#fff',
                },
                outlined: {
                    color: '#000',
                    border: 'none',
                },
                text: {
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'rgba(0,0,0,0.5)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.MuiOtpInput-TextField': {
                        position: 'relative',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#647991',
                    height: height.xl,
                    border: 1,
                    borderColor: '#E3ECF3',
                    borderRadius: borderRadius.xl,
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '24px',
                    '&.MuiOutlinedInput-root': {
                        paddingLeft: 8,
                    },
                    '&.Mui-focused': {
                        borderColor: '#000',
                        color: '#000',
                    },
                    '&.Mui-error': {
                        borderColor: 'rgba(228, 63, 63, 0.2);',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#EFF3F7',
                        borderColor: '#EFF3F7',
                        color: '#647991',
                        WebkitTextFillColor: '#647991',
                    },
                },
                input: {
                    '&.Mui-disabled': {
                        borderRadius: borderRadius.xl,
                    },
                    '&.MuiInputBase-inputAdornedStart': {
                        paddingLeft: 4,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: borderRadius.xl,
                    '&.Mui-disabled.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EFF3F7',
                    },
                },
                input: {
                    padding: '8px 12px',
                    '&.Mui-disabled': {
                        backgroundColor: '#EFF3F7',
                        borderColor: '#EFF3F7',
                        color: '#647991',
                        WebkitTextFillColor: '#647991',
                    },
                },
                notchedOutline: {
                    borderColor: '#EFF3F7',
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: '-10px',
                    '&.MuiInputAdornment-positionEnd': {
                        marginRight: 0,
                    },
                    '&.MuiInputAdornment-positionStart': {
                        marginRight: 0,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        position: 'relative',
                        paddingLeft: 10,
                        '&:before': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            content: '"!"',
                            color: palette.error.main,
                            width: 16,
                            height: 16,
                            top: 2,
                            left: -10,
                            borderRadius: '50%',
                            backgroundColor: palette.error.light,
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderColor: '#E3ECF3',
                },
                colorDefault: {
                    '&.MuiChip-filledDefault': {
                        backgroundColor: palette.background.default,
                    },
                },
                colorSecondary: {
                    backgroundColor: '#FF00E5',
                },
                colorInfo: {
                    backgroundColor: '#0044FF',
                },
                colorWarning: {
                    backgroundColor: '#F6F17A',
                },
                label: {
                    fontSize: '14px',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: palette.primary.main,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    color: palette.primary.main,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: 'none',
                    borderRadius: '16px',
                    backgroundColor: palette.background.default,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '8px',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '8px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                    backgroundColor: palette.background.default,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '4px 8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        },
    },
});
