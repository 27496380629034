export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.56249 14.5C4.65214 14.5784 4.75071 14.6505 4.85822 14.715L10.9709 18.3826C11.6043 18.7626 12.3955 18.7626 13.0289 18.3826L19.1416 14.715C19.2491 14.6505 19.3477 14.5784 19.4373 14.5C20.4273 15.3658 20.3288 17.0027 19.1416 17.715L13.0289 21.3826C12.3955 21.7626 11.6043 21.7626 10.9709 21.3826L4.85822 17.715C3.67104 17.0027 3.57246 15.3658 4.56249 14.5Z"
            fill="#0029FF"
            fillOpacity="0.2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.56249 10.5C4.65214 10.5784 4.75071 10.6505 4.85822 10.715L10.9709 14.3826C11.6043 14.7626 12.3955 14.7626 13.0289 14.3826L19.1416 10.715C19.2491 10.6505 19.3477 10.5784 19.4373 10.5C20.4273 11.3658 20.3288 13.0027 19.1416 13.715L13.0289 17.3826C12.3955 17.7626 11.6043 17.7626 10.9709 17.3826L4.85822 13.715C3.67104 13.0027 3.57246 11.3658 4.56249 10.5Z"
            fill="#0029FF"
            fillOpacity="0.5"
        />
        <path
            d="M5.11556 6.71376L11.2283 3.04614C11.7033 2.76113 12.2967 2.76113 12.7717 3.04614L18.8844 6.71376C19.8554 7.29637 19.8555 8.70363 18.8844 9.28624L12.7717 12.9539C12.2967 13.2389 11.7033 13.2389 11.2283 12.9539L5.11556 9.28624C4.14455 8.70363 4.14455 7.29637 5.11556 6.71376Z"
            stroke="#0029FF"
        />
    </svg>
);
