import { Box, Typography, FormControl, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomSwitch from 'src/components/CustomSwitch';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

type PointsProps = {
    control: any;
    name: string;
    points?: number;
};

const Points = ({ points = 0, control, name }: PointsProps) => {
    const isMobile = useIsMobileSize();
    const borderColor = isMobile ? '#E3ECF3' : '#CCCCCC';

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    style={{
                        border: '1px solid',
                        borderColor,
                        borderRadius: '16px',
                        width: '100%',
                        backgroundColor: '#fff'
                    }}
                >
                    <FormControlLabel
                        labelPlacement="start"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px',
                            marginLeft: 0,
                            marginRight: 0,
                        }}
                        control={
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                py={1}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="6px"
                                >
                                    <Typography
                                        variant="body2"
                                        color="secondary"
                                    >
                                        {points > 0 ? points : 'Нет баллов'}
                                    </Typography>
                                    <CustomSwitch
                                        disabled={points === 0}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                </Box>
                            </Box>
                        }
                        label="Баллы"
                    />
                </FormControl>
            )}
        />
    );
};

export default Points;
