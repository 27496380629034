import { object, string, TypeOf } from 'zod';

const CreatePasswordScheme = object({
    new_password: string().min(1, 'Поле пароля обязательное'),
    new_password2: string().min(1, 'Поле подтверждения пароля обязательное'),
}).refine((data) => data.new_password === data.new_password2, {
    path: ['new_password2'],
    message: 'Пароли не совпадают',
});

export type TypeCreatePasswordScheme = TypeOf<typeof CreatePasswordScheme>;

export default CreatePasswordScheme;
