import { Box } from '@mui/material';

import FilterItem from '../FeedFilter/Components/FilterItem';
import { getBundlesFilter } from '../FeedFilter/utils';
import BottomSheetHeader from '../BottomSheetHeader';
import useProjectsStore from 'src/stores/projects';

export default function BundlesSheet() {
    const { activeProject } = useProjectsStore(({ activeProject }) => ({ activeProject }));
    const bundles = activeProject?.bundles.filter((bundle) => bundle.is_public) || [];

    const item = getBundlesFilter(bundles, true);

    return (
        <Box px={1}>
            <BottomSheetHeader title="Подборки" />
            <FilterItem
                key={item.title}
                {...item}
            />
        </Box>
    );
}
