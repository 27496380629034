import {
    Box,
    Button,
    // Typography,
    Link,
    Typography,
} from '@mui/material';
import ym from 'react-yandex-metrika';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import LoginField from 'src/components/LoginField';
import PasswordField from 'src/components/PasswordField';
import WidgetWrapper from 'src/components/FormWrapper';
// import TelegramButton from 'src/widgets/TelegramButton'
// import ButtonText from 'src/components/ButtonText'
import useGoTo from 'src/hooks/useGoTo';
import ROUTES from 'src/router/routes';

import scheme, { type TypeRegisterScheme } from 'src/validations/register';
import useSessionStore from 'src/stores/session';
import CustomAccordion from 'src/components/CustomAccordion';

import { ROUTES_URL } from 'src/constants/routes';

import { useMutation } from 'react-query';
import SessionService from 'src/services/SessionService';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

import type { SubmitHandler } from 'react-hook-form';

export default function RegisterForm() {
    const goTo = useGoTo();

    const session = useSessionStore(({ isAuthorized }) => ({
        isAuthorized,
    }));

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isValid },
    } = useForm<TypeRegisterScheme>({
        resolver: zodResolver(scheme),
        resetOptions: {
            keepIsSubmitted: true,
        },
    });

    const { mutateAsync: login, isLoading: isLoginLoading } = useMutation(
        SessionService.queryKey,
        useSessionStore.getState().login,
        {
            onError: () => {
                goTo(ROUTES_URL.LOGIN);
            },
            onSuccess: () => {
                goTo(ROUTES_URL.WELCOME_SCREEN);
            },
        }
    );

    const { mutateAsync: register, isLoading: isRegisterLoading } = useMutation(
        SessionService.queryKey,
        useSessionStore.getState().register,
        {
            onError: (error: Obj) => {
                Object.keys(error).forEach((key) => {
                    setError(key as any, { message: error[key][0] });
                });
            },
        }
    );

    const onSubmit: SubmitHandler<TypeRegisterScheme> = async (data, event) => {
        try {
            await register({
                ...data,
                username: data.email,
            });
            ym('reachGoal', '350175706');

            await login({ email: data.email, password: data.password });
        } catch (err) {
            console.info(err);
        }
    };

    const isLoading = isRegisterLoading || isLoginLoading;

    if (session.isAuthorized) return <Redirect to={'/'} />;

    return (
        <LoadingWrapper isLoading={isLoading}>
            <WidgetWrapper
                name="Регистрация"
                topGutter
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mb={2}>
                        <Controller
                            name="email"
                            control={control}
                            disabled={isLoading}
                            render={({ field }) => (
                                <LoginField
                                    {...field}
                                    label="Почта"
                                    placeholder="user@sample.com"
                                    error={errors?.email?.message}
                                />
                            )}
                        />
                    </Box>
                    <Box mb={2}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <PasswordField
                                    {...field}
                                    label="Пароль"
                                    placeholder="Введите пароль"
                                    error={errors?.password?.message}
                                />
                            )}
                            disabled={isLoading}
                        />
                    </Box>
                    <Box mb={2}>
                        <Controller
                            name="password2"
                            control={control}
                            disabled={isLoading}
                            render={({ field }) => (
                                <PasswordField
                                    {...field}
                                    label="Подтверждение"
                                    placeholder="Повторите пароль"
                                    error={errors?.password2?.message}
                                />
                            )}
                        />
                    </Box>
                    <Box mb={2}>
                        <Button
                            disabled={isLoading || !isValid}
                            variant="contained"
                            size="large"
                            type="submit"
                            fullWidth
                        >
                            Создать аккаунт
                        </Button>
                    </Box>
                    <Box>
                        <Typography
                            variant="body2"
                            color="textDisabled"
                            textAlign="center"
                            fontWeight={400}
                        >
                            Нажимая «Создать аккаунт», вы принимаете следующие условия 
                            <Link
                                textAlign="center"
                                variant="caption"
                                color="primary"
                                component={'a'}
                                href={'https://tghunter.ru/terms'}
                                target="_blank"
                                fontWeight={400}
                            >
                                пользовательского соглашения
                            </Link>
                        </Typography>
                    </Box>
                    <Box mb={0.5}>
                        <CustomAccordion
                            title="Как создать пароль"
                            display="flex"
                            justifyContent="center"
                        >
                            <Box>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    Для создания пароля разрешается применять следующие символы:
                                </Typography>
                                <ul
                                    style={{
                                        listStyle: 'outside',
                                    }}
                                >
                                    <li>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            Заглавные латинские буквы: от A до Z (26 символов)
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            Строчные латинские буквы: от a до z (26 символов)
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            Цифры от 0 до 9 (10 символов)
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            {
                                                'Символы: (пробел) ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [  ] ^ _` { | } ~ (33 символа)'
                                            }
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </CustomAccordion>
                    </Box>
                </form>
            </WidgetWrapper>
            <Box
                mt={1}
                display="flex"
                justifyContent="center"
            >
                <Link
                    textAlign="center"
                    variant="caption"
                    color="primary"
                    component={RouterLink}
                    to={ROUTES.LOGIN.path}
                >
                    Есть аккаунт? Войти
                </Link>
            </Box>
        </LoadingWrapper>
    );
}
