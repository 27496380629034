import { Box, Typography } from '@mui/material';
import { WithoutHeader, DesktopLayout } from 'src/layout';

import useUserStore from 'src/stores/user';
import useSubscriptionsStore from 'src/stores/subscriptions';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import CurrentTariff from './components/CurrentTariff';
import Tariff from './components/Tariff';

const MyTariffScene = () => {
    const isMobile = useIsMobileSize();
    const { current_subscription } = useUserStore(({ current_subscription }) => ({ current_subscription }));
    const { subscriptions } = useSubscriptionsStore(({ subscriptions }) => ({ subscriptions }));

    const subscriptionsList = subscriptions.filter((item) => {
        if (current_subscription) return item.id !== current_subscription.plan.id;
        return item;
    });

    const renderContent = () => {
        return (
            <>
                <Box mb={2}>
                    {current_subscription && (
                        <Box mb={1}>
                            <Typography variant="h3">Активный тариф</Typography>
                        </Box>
                    )}

                    <Box width={1}>
                        {current_subscription && (
                            <CurrentTariff
                                subscription={{
                                    ...current_subscription.plan,
                                    end_date: current_subscription.end_date,
                                    duration_months: current_subscription.duration_months,
                                    is_active: current_subscription.is_active,
                                    is_recurring: current_subscription.is_recurring,
                                }}
                            />
                        )}
                        {!current_subscription && (
                            <Typography
                                variant="body1"
                                color="primary"
                            >
                                У вас нет активного тарифа
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Box mb={1}>
                        <Typography variant="h3">Другие тарифы</Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        {subscriptionsList.sort((a, b) => {
                            if (+a.price < +b.price) return -1;
                            if (+a.price > +b.price) return 1;
                            return 0;
                        }).map((subscription) => {
                            return (
                                <Tariff
                                    key={subscription.id}
                                    subscription={subscription}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </>
        );
    };

    if (!isMobile) {
        return (
            <DesktopLayout>
                <Box>{renderContent()}</Box>
            </DesktopLayout>
        );
    }

    return (
        <WithoutHeader
            showBack
            showMyHeader
            title="Ваш тариф"
        >
            {renderContent()}
        </WithoutHeader>
    );
};

export default MyTariffScene;
