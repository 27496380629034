export default () => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.2724 7.8181L15.0394 8.75H16H20.1886C20.4076 8.75 20.5207 9.01157 20.3708 9.17117L8.92681 21.3535C8.87144 21.4124 8.82253 21.4296 8.78416 21.4347C8.73895 21.4408 8.68313 21.4324 8.62824 21.4036C8.57335 21.3747 8.53485 21.3334 8.51421 21.2928C8.49669 21.2583 8.48312 21.2082 8.5003 21.1292L10.2329 13.1593L10.4306 12.25H9.5H6.80925C6.64441 12.25 6.52469 12.0933 6.56806 11.9342L8.62067 4.40799C8.88764 3.4291 9.77675 2.75 10.7914 2.75H16.2192C16.3819 2.75 16.5012 2.90285 16.4618 3.06063L15.2724 7.8181Z"
            fill="white"
            stroke="url(#paint0_linear_3809_24276)"
            strokeWidth="1.5"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3809_24276"
                x1="17.5"
                y1="1.5"
                x2="8.5"
                y2="21.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop
                    offset="0.015"
                    stopColor="#DED60B"
                />
                <stop
                    offset="0.695"
                    stopColor="#FFE299"
                />
                <stop
                    offset="1"
                    stopColor="#FF8787"
                />
            </linearGradient>
        </defs>
    </svg>
);
