import { useState } from 'react';
import { Box, Button, TextField, InputAdornment } from '@mui/material';
import Backspace from 'src/icons/Backspace';

import type { ReactNode, ChangeEvent } from 'react';

type AddItemFieldProps = {
    icon?: ReactNode;
    placeholder?: string;
    onAddItem: (value: string) => void;
    disabled?: boolean;
};

export default function AddItemField({ icon = null, placeholder = '', disabled, onAddItem }: AddItemFieldProps) {
    const [value, setValue] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleClear = () => {
        setValue('');
    };

    const onAddItemHandler = () => {
        onAddItem(value);
        handleClear();
    };

    return (
        <Box
            display="flex"
            gap="4px"
        >
            <TextField
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                slotProps={{
                    input: {
                        sx: {
                            background: '#fff',
                        },
                        startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
                        endAdornment: value.length > 0 && (
                            <InputAdornment position="end">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    onClick={handleClear}
                                    width={19}
                                    height={47}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Backspace />
                                </Box>
                            </InputAdornment>
                        ),
                    },
                }}
                fullWidth
            />
            {value.length > 0 && (
                <Button
                    variant="contained"
                    size="large"
                    onClick={onAddItemHandler}
                >
                    +
                </Button>
            )}
        </Box>
    );
}
