import { type ReactNode } from 'react';
import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';
import { Box, CircularProgress } from '@mui/material';

type Props = {
    isLoading: boolean;
    hasNextPage: boolean;
    fetchNext: () => unknown;
    children: ReactNode;
};

export default function InfinityScroll({ fetchNext, isLoading, children, hasNextPage }: Props) {
    const { ref } = useIntersectionObserver<HTMLDivElement>({
        threshold: 1,
        onChange: (entry) => {
            if (entry.isIntersecting) {
                fetchNext();
            }
        },
    });

    return (
        <Box>
            {children}
            {isLoading && (
                <Box
                    width={'100%'}
                    height={'30px'}
                    display="flex"
                    justifyContent="center"
                >
                    <CircularProgress size="30px" />
                </Box>
            )}
            {hasNextPage && (
                <Box
                    ref={ref}
                    width={1}
                    height="1px"
                />
            )}
        </Box>
    );
}
