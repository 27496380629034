import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import WidgetWrapper from 'src/components/FormWrapper';
import PublicLayout from 'src/layout/PublicLayout';
import LayoutPaper from 'src/layout/layoutPaper';
import RestorePasswordForm from 'src/widgets/RestorePasswordForm';
import useRestoreStore from 'src/stores/restorePassword';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import useSessionStore from 'src/stores/session';
import { DesktopLayout, WithoutHeader } from 'src/layout';
import { Box, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

export default function RestorePasswordScene() {
    const { isAuthorized } = useSessionStore(({ isAuthorized }) => ({ isAuthorized }));
    const isMobile = useIsMobileSize();
    const [isSubmit, setIsSubmit] = useState(false);
    const { restorePassword, email } = useRestoreStore();

    const getCode = async () => {
        setIsSubmit(true);

        try {
            restorePassword();
        } catch (error) {
            console.info(error);
            enqueueSnackbar('Ошибка отправки кода');
        }
    };

    const renderContent = () => {
        return (
            <WidgetWrapper
                name="Восстановление доступа"
                topGutter
                // sx={{
                //     backgroundColor: isSubmit ? undefined : 'none',
                // }}
            >
                {isSubmit ? (
                    <RestorePasswordForm />
                ) : (
                    <Box mb={2}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={getCode}
                            fullWidth
                        >
                            Подтвердить смену пароля
                        </Button>
                    </Box>
                )}
            </WidgetWrapper>
        );
    };

    if (!email) {
        return <Redirect to={'/'} />;
    }

    if (!isAuthorized) {
        return (
            <LayoutPaper>
                <PublicLayout>{renderContent()}</PublicLayout>
            </LayoutPaper>
        );
    }

    if (isMobile) {
        return <WithoutHeader showBack>{renderContent()}</WithoutHeader>;
    }

    return <DesktopLayout>{renderContent()}</DesktopLayout>;
}
