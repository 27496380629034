import { useRef } from 'react';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import OtpField from 'src/components/OtpField';
import SendCodeAgain from 'src/widgets/SendCodeAgain';
import SessionService from 'src/services/SessionService';
import useUserStore from 'src/stores/user';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';
import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

type ApproveEmailForm = {
    code: string;
};

export default function ApproveEmail() {
    const ref = useRef<HTMLButtonElement | null>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ApproveEmailForm>({
        defaultValues: {
            code: '',
        },
    });

    const { mutateAsync: refetchEmailCode, isLoading: isRefetchEmailCodeLoading } = useMutation(
        SessionService.queryKey,
        useUserStore.getState().refetchEmailCode,
        {
            onSuccess: () => {
                enqueueSnackbar('Код отправлен на вашу почту', { variant: 'success' });
                reset();
            },
            onError: (error) => {
                console.info(error);
                enqueueSnackbar('Ошибка отправки кода', { variant: 'error' });
            },
        }
    );

    const { mutate: confirmEmail, isLoading: confirmEmailLoading } = useMutation(
        SessionService.queryKey,
        useUserStore.getState().confirmEmail,

        {
            onSuccess: () => {
                enqueueSnackbar('Почта подверждена!', { variant: 'success' });
                updateBeginWorkStep({ currentStep: BEGIN_WORK_STEPS_IDS.EMAIL_CONFIRM });
            },
            onError: (error) => {
                console.info(error);
                enqueueSnackbar('Ошибка подтверждения почты', { variant: 'error' });
            },
        }
    );

    const onSubmit = ({ code }: ApproveEmailForm) => {
        confirmEmail(code);
    };

    const handleComplete = () => {
        if (ref.current) ref.current.click();
    };

    const handleRefetchCode = async () => {
        return refetchEmailCode();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoadingWrapper
                isLoading={confirmEmailLoading || isRefetchEmailCodeLoading}
                isLocal
            >
                <Box mb={2}>
                    <Controller
                        name="code"
                        control={control}
                        render={({ field }) => (
                            <OtpField
                                {...field}
                                error={errors?.code?.message}
                                onComplete={handleComplete}
                            />
                        )}
                    />
                </Box>
                <Box mb={0.5}>
                    <SendCodeAgain
                        timer={30}
                        disabled={confirmEmailLoading}
                        onClick={handleRefetchCode}
                    />
                </Box>
                <Box display="none">
                    <button
                        type="submit"
                        ref={(r) => (ref.current = r)}
                    ></button>
                </Box>
            </LoadingWrapper>
        </form>
    );
}
