export default ({ open = true }) => {
    if (open)
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 12C22 13.8104 20.993 15.5368 19.19 16.8481C17.388 18.1587 14.8492 19 12 19C9.15083 19 6.61202 18.1587 4.81 16.8481C3.007 15.5368 2 13.8104 2 12C2 10.1896 3.007 8.46315 4.81 7.15188C6.61202 5.84132 9.15083 5 12 5C14.8492 5 17.388 5.84132 19.19 7.15188C20.993 8.46315 22 10.1896 22 12ZM23 12C23 16.4183 18.0751 20 12 20C5.92487 20 1 16.4183 1 12C1 7.58172 5.92487 4 12 4C18.0751 4 23 7.58172 23 12ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                    fill="black"
                />
            </svg>
        );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.569 5.58252C16.7358 4.58844 14.4624 4 12 4C5.92487 4 1 7.58172 1 12C1 14.6914 2.82741 17.0723 5.62907 18.5224L8.54101 15.6105C7.59131 14.7004 7 13.4192 7 12C7 9.23858 9.23858 7 12 7C13.4192 7 14.7004 7.59131 15.6105 8.54101L18.569 5.58252ZM14.9032 9.24832C14.1742 8.47949 13.1431 8 12 8C9.79086 8 8 9.79086 8 12C8 13.1431 8.47949 14.1742 9.24832 14.9032L14.9032 9.24832ZM10.2504 15.5981L15.5981 10.2504C15.8556 10.7789 16 11.3726 16 12C16 14.2091 14.2091 16 12 16C11.3726 16 10.7789 15.8556 10.2504 15.5981ZM9.51106 16.3375C10.244 16.7589 11.0938 17 12 17C14.7614 17 17 14.7614 17 12C17 11.0938 16.7589 10.244 16.3375 9.51106L19.6189 6.22962C21.7029 7.68538 23 9.73253 23 12C23 16.4183 18.0751 20 12 20C10.1182 20 8.34679 19.6563 6.79791 19.0506L9.51106 16.3375Z"
                    fill="black"
                />
                <path
                    d="M21.5 2.5L2.5 21.5"
                    stroke="black"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
