export const TgArrowIcon = () => {
    return (
        <svg
            width="28"
            height="34"
            viewBox="0 0 28 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.0133 8.53863L23.6986 8.92646L24.0133 8.53863C23.8235 8.38465 23.604 8.30648 23.432 8.26537C23.2567 8.22351 23.0913 8.21082 22.9758 8.21285C22.5396 8.22054 22.0329 8.34283 21.0373 8.70411C20.0331 9.06851 18.4782 9.69723 15.9228 10.7601C14.1279 11.5067 10.5465 13.0493 5.18251 15.3863C4.73213 15.5659 4.35727 15.7553 4.08043 15.9609C3.81507 16.1579 3.55514 16.433 3.50873 16.8032C3.47124 17.1022 3.55609 17.3695 3.7321 17.5858C3.89219 17.7826 4.11119 17.9175 4.31952 18.0184C4.63222 18.1699 5.04916 18.3008 5.47083 18.4333C5.59445 18.4721 5.71848 18.511 5.84041 18.5507C6.31741 18.7057 6.88025 18.8751 7.41075 19.0082C7.92965 19.1383 8.45742 19.2441 8.8469 19.2525C9.58774 19.2685 10.3559 18.9637 11.1362 18.4283C13.6431 16.736 15.5424 15.4631 16.8347 14.6089C17.1496 14.4008 17.4279 14.2178 17.67 14.0599C17.2611 14.4627 16.7721 14.9303 16.2769 15.3979C15.662 15.9787 15.0292 16.5671 14.5379 17.0241C14.0675 17.4616 13.7267 17.7786 13.6553 17.8527C13.5327 17.98 13.4068 18.1025 13.2792 18.2256C13.2647 18.2396 13.2501 18.2536 13.2355 18.2676C13.1239 18.3753 13.0099 18.4851 12.904 18.5929C12.6722 18.8288 12.4268 19.1048 12.2888 19.4195C12.1358 19.7683 12.1186 20.1566 12.3248 20.5515C12.5121 20.9103 12.863 21.2333 13.3437 21.55C14.0925 22.0435 14.6915 22.4514 15.2921 22.8604L15.3745 22.9165C16.0031 23.3446 16.6402 23.7776 17.4546 24.3114C17.6612 24.4469 17.8578 24.587 18.0582 24.7299C18.0991 24.759 18.1402 24.7883 18.1815 24.8177C18.4205 24.9874 18.668 25.1599 18.9215 25.3088C19.4313 25.6083 20.0096 25.8397 20.6919 25.7769C21.5424 25.6987 22.129 24.9106 22.3961 23.4829C23.0059 20.2231 24.2093 13.1355 24.4889 10.198C24.5186 9.88599 24.4826 9.51312 24.4531 9.34128C24.4233 9.16676 24.3455 8.80826 24.0133 8.53863ZM18.8147 13.3406C18.8145 13.3408 18.8117 13.3422 18.8066 13.3444C18.8123 13.3416 18.8148 13.3405 18.8147 13.3406Z"
                stroke="#1F1F1F"
            />
        </svg>
    );
};
