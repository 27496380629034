import { Box, Typography } from '@mui/material';
import { WithoutHeader } from 'src/layout';
import SelectEntityForm from 'src/widgets/SelectEntityForm';

export default function SelectChatsAndChannels() {
    return (
        <WithoutHeader showBack>
            <Box mb={2.5}>
                <Typography variant="h2">Создание проекта</Typography>
                <Typography variant="h2">1. Чаты и каналы</Typography>
            </Box>
            <SelectEntityForm
                name="chats"
                options={[]}
                buttonTitle="Далее"
            />
        </WithoutHeader>
    );
}
