import { useState, useEffect } from 'react';
import { Box, Typography, Collapse } from '@mui/material';

import Checked from 'src/icons/Checked';
import ChevroneRight from 'src/icons/ChevroneRight';

import type { ReactNode } from 'react';

export type CustomStepperItemProps = {
    currentStep: number;
    step: number;
    title?: string;
    children?: ReactNode;
    titleDone?: string;
    done?: boolean;
    chip?: ReactNode;
    showStep?: boolean;
    isExpanded?: boolean;
    onClick?: () => void;
    sx?: Obj,
    // withShadow?: boolean,
};

export default function CustomStepperItem({
    currentStep,
    title = '',
    step,
    titleDone = '',
    children = null,
    done = false,
    chip = null,
    showStep = true,
    isExpanded = false,
    sx = {},
    onClick,
}: // withShadow = true,
CustomStepperItemProps) {
    const [expanded, setExpanded] = useState(isExpanded);

    const isActive = currentStep === step;
    const isСlickable = isActive || done;

    useEffect(() => {
        if (done && expanded) setExpanded(false);
    }, [done]);

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    const makeStyle = () => {
        if (isСlickable) {
            return {
                wrapper: {
                    bgcolor: 'background.default',
                    border: 'none',
                },
                typography: 'primary',
            };
        }

        return {
            wrapper: {
                bgcolor: 'background.paper',
                border: '1px solid #E3ECF3',
            },
            typography: 'secondary',
        };
    };

    const handleClick = () => {
        if (!isСlickable) {
            return;
        }

        onClick?.();

        if (children) {
            setExpanded(true);
        }
    };

    return (
        <Box
            px={1.5}
            py={1.5}
            onClick={handleClick}
            borderRadius={4}
            minHeight={48}
            style={{
                cursor: isСlickable ? 'pointer' : 'default',
                boxShadow: !children && !done && isActive ? '0px 4px 12px 0px rgba(68, 73, 103, 0.20)' : 'none',
            }}
            sx={{
                ...sx
            }}
            {...makeStyle().wrapper}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1.5}
                >
                    {showStep && (
                        <Box
                            width={24}
                            height={24}
                            flexShrink={0}
                        >
                            {done ? (
                                <Checked />
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width={1}
                                    height="100%"
                                    borderRadius="50%"
                                    border="1px solid #E3ECF3"
                                >
                                    <Typography
                                        variant="body2"
                                        color={makeStyle().typography}
                                    >
                                        {step}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                    <Typography
                        variant="body1"
                        color={makeStyle().typography}
                    >
                        {done ? titleDone || title : title}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    {chip}
                    {isСlickable && (!expanded || !children) && (
                        <Box>
                            <ChevroneRight />
                        </Box>
                    )}
                </Box>
            </Box>
            {children && (
                <Collapse in={expanded}>
                    <Box
                        pt={2}
                        pr={0}
                        pl={36 / 8}
                        pb={1}
                    >
                        {children}
                    </Box>
                </Collapse>
            )}
        </Box>
    );
}
