export const ProfileIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5852_84012)">
                <g clipPath="url(#clip1_5852_84012)">
                    <rect
                        x="1.5"
                        y="1.5"
                        width="21"
                        height="21"
                        rx="10.5"
                        stroke="#1F1F1F"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0001 10C16.0001 12.2091 14.2093 14 12.0001 14C9.79098 14 8.00012 12.2091 8.00012 10C8.00012 7.79086 9.79098 6 12.0001 6C14.2093 6 16.0001 7.79086 16.0001 10ZM17.0001 10C17.0001 12.7614 14.7615 15 12.0001 15C9.2387 15 7.00012 12.7614 7.00012 10C7.00012 7.23858 9.2387 5 12.0001 5C14.7615 5 17.0001 7.23858 17.0001 10ZM9.00013 16L8.95641 16C8.53369 16 8.27116 15.9999 8.04329 16.0299C6.47272 16.2367 5.23684 17.4726 5.03007 19.0432C5.00007 19.271 5.00009 19.5336 5.00012 19.9563L5.00013 20H6.00013C6.00013 19.5197 6.0012 19.328 6.02151 19.1737C6.16921 18.0519 7.05198 17.1691 8.17381 17.0214C8.32808 17.0011 8.51981 17 9.00013 17H15.0001C15.4804 17 15.6722 17.0011 15.8264 17.0214C16.9483 17.1691 17.831 18.0519 17.9787 19.1737C17.999 19.328 18.0001 19.5197 18.0001 20H19.0001L19.0001 19.9563C19.0002 19.5336 19.0002 19.271 18.9702 19.0432C18.7634 17.4726 17.5275 16.2367 15.957 16.0299C15.7291 15.9999 15.4666 16 15.0438 16L15.0001 16H9.00013Z"
                    fill="#1F1F1F"
                />
            </g>
            <defs>
                <clipPath id="clip0_5852_84012">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="clip1_5852_84012">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
