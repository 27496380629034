import { useState, useEffect } from 'react';
import EventService from 'src/services/EventService';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import MenuComponent from './Menu';

const Menu = () => {
    const isMobile = useIsMobileSize();
    const [open, setIsOpen] = useState(false);

    useEffect(() => {
        const subscriberOpenEvent = EventService.subscribe('OPEN_MENU', () => {
            setIsOpen(true);
        });
        const subscriberCloseEvent = EventService.subscribe('CLOSE_MENU', () => {
            setIsOpen(false);
        });

        return () => {
            subscriberOpenEvent.unsubscribe();
            subscriberCloseEvent.unsubscribe();
        };
    }, []);

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isMobile) return null;

    return (
        <MenuComponent
            open={open}
            onClose={handleClose}
            anchore="right"
            variant="temporary"
        />
    );
};

export default Menu;
