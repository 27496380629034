import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import DescribeYourBusiness, { IChatsAndChannelsScreenProps } from './Content';

export default function DescribeYourBusinessScreen(props: IChatsAndChannelsScreenProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showBack>
                <DescribeYourBusiness {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout>
            <DescribeYourBusiness {...props} />
        </DesktopLayout>
    );
}
