import { forwardRef, ChangeEvent } from 'react';
import { Box, FormControl, FormHelperText, FormLabel, InputAdornment, Typography, TextField } from '@mui/material';
import Mail from 'src/icons/Mail';
// import Phone from 'src/icons/Phone'
import Backspace from 'src/icons/Backspace';
// import PhoneField from './PhoneMaskField'
// import { isInputPhoneNumber } from './utils'

type LoginFieldProps = {
    label?: string;
    name: string;
    value?: string;
    error?: string;
    placeholder?: string;
    onChange: (value: string) => void;
};

const LoginField = forwardRef(
    ({ name, error, value = '', label, placeholder = '', onChange, ...otherProps }: LoginFieldProps, ref) => {
        // const [type, setType] = useState<'phone' | 'email'>('email');
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        };

        const handleClear = () => {
            onChange('');
        };

        return (
            <FormControl
                fullWidth
                error={!!error}
            >
                {label && (
                    <FormLabel>
                        <Box
                            ml={1}
                            mb={1}
                        >
                            <Typography
                                color="primary"
                                variant="body1"
                            >
                                {label}
                            </Typography>
                        </Box>
                    </FormLabel>
                )}

                <TextField
                    type="text"
                    ref={ref as any}
                    name={name}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Mail />
                                </InputAdornment>
                            ),
                            endAdornment: value.length > 0 && (
                                <InputAdornment position="end">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        onClick={handleClear}
                                        width={19}
                                        height={47}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Backspace />
                                    </Box>
                                </InputAdornment>
                            ),
                        },
                    }}
                    onChange={handleChange}
                    placeholder={placeholder}
                    value={value}
                    error={!!error}
                    {...otherProps}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        );
    }
);

export default LoginField;
