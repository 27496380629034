import { useTheme } from '@emotion/react';
import { Box, styled } from '@mui/material';

import useIsMobileSize from 'src/hooks/useIsMobileSize';
import Filters from 'src/widgets/FeedFilter/Desktop';
import MenuComponent from 'src/widgets/Menu/Menu';

interface IDesktopLayoutProps {
    children: React.ReactNode;
    withMainContentWrapper?: boolean;
}

export default function DesktopLayout({ children, withMainContentWrapper }: IDesktopLayoutProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) return <>{children}</>;

    return (
        <Box
            display="grid"
            gridTemplateColumns={`300px minmax(400px,1fr) 330px`}
            bgcolor="#EFF3F7"
            minHeight="100%"
            maxWidth={1920}
            margin="auto"
        >
            <MenuComponent
                open={true}
                anchore="left"
                variant="permanent"
                onClose={() => {}}
            />

            <Box
                px={7}
                py={5}
            >
                {withMainContentWrapper ? <MainContentWrapper>{children}</MainContentWrapper> : children}
            </Box>
            <Filters />
        </Box>
    );
}

interface IMainContentWrapperProps {
    children: React.ReactNode;
}

const Wrapper = styled(Box)(({ theme }) => ({
    padding: '1rem',
    background: theme.palette.background.default,
    height: '100%',
}));

export const MainContentWrapper = ({ children }: IMainContentWrapperProps) => {
    return <Wrapper>{children}</Wrapper>;
};
