import { Link, Stack, styled, Switch, SwitchProps, Typography, useTheme } from '@mui/material';

import { ROUTES_URL } from 'src/constants/routes';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { AIIcon, AIIconBlack } from 'src/icons/AIIcon';
import { useAIFilter } from 'src/providers/AIFilterProvider';

export default function AIFilter() {
    const isTablet = useIsMobileSize();
    const theme = useTheme();

    const { aiFilter, setAiFilter } = useAIFilter();

    const handleAiFilterChange = (event) => {
        setAiFilter(event.target.checked);
    };

    return (
        <Stack gap={0.5}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    bgcolor: '#FFFFFF',
                    borderRadius: '16px',
                    boxShadow: aiFilter
                        ? '-6px -6px 24px 0px rgba(0, 163, 255, 0.30), 6px 6px 24px 0px rgba(250, 0, 255, 0.30) '
                        : 'none',
                    border: aiFilter ? '1px solid transparent' : `1px solid ${theme.palette.grey[400]}`,
                    padding: '12px',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        svg: {
                            width: '22px',
                            height: '22px',
                        },
                        p: 0,
                    }}
                >
                    {aiFilter ? <AIIcon /> : <AIIconBlack />}

                    <Typography
                        fontWeight="600"
                        variant="h4"
                    >
                        AI ChatGPT
                    </Typography>
                </Stack>

                <IOSSwitch
                    checked={aiFilter}
                    onChange={handleAiFilterChange}
                />
            </Stack>

            <Typography
                variant="body2"
                sx={{ fontSize: isTablet ? '14px' : '12px' }}
            >
                Искусственный Интеллект определяет релевантность сообщений для вашего бизнеса.&nbsp;
                <Link
                    textAlign="center"
                    variant="caption"
                    color="textPrimary"
                    component={'a'}
                    target="_blank"
                    href={ROUTES_URL.BEGIN_WORK_DESCRIPTION}
                    sx={{
                        color: '#0044FF',
                        textDecoration: 'none',
                        fontSize: isTablet ? '14px' : '12px',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    Посмотреть настройки ИИ
                </Link>
            </Typography>
        </Stack>
    );
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',

        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundImage: 'linear-gradient(68deg, #B236FF 0%, #7E7AFF 37.39%, #42C8FF 78.84%, #18FFFF 91.14%)',
                opacity: 1,
                border: 0,
                backgroundColor: '#E9E9EA',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },

        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,

            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));
