import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import Sidebar from 'src/icons/Sidebar';
import Remove from 'src/icons/Remove';
import useProjectsStore from 'src/stores/projects';
import useKeywordStore from 'src/stores/keywords';
import useCollectionsStore from 'src/stores/collections';
import KeywordsService from 'src/services/KeywordsService';
import useFilterStore from 'src/stores/filter';
import { ROUTES_URL } from 'src/constants/routes';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

import useUserStore from 'src/stores/user';

import { getFilters } from '../utils';
import AIFilter from 'src/components/AIFilter';
import FilterItem from '../Components/FilterItem';

export default function Filters() {
    const isOnboardingCompleted = useUserStore(({ isOnboardingCompleted }) => isOnboardingCompleted);
    const { activeProject } = useProjectsStore(({ activeProject }) => ({ activeProject }));
    const keywords = useKeywordStore(({ keywords }) => keywords);
    const [open, setOpen] = useState(isOnboardingCompleted);
    const location = useLocation();

    const { isLoading: isLoadingKeywords } = useQuery({
        queryKey: KeywordsService.queryKey,
        queryFn: useKeywordStore.getState().fetchKeywords,
        onError: () => {
            enqueueSnackbar({ message: 'Ошибка получения ключевых слов', variant: 'error' });
        },
        enabled: keywords.length < 1,
    });

    const { privateCollection: privateChats } = useCollectionsStore(({ privateCollection }) => ({ privateCollection }));

    const handleChangeOpen = () => {
        setOpen((old) => !old);
    };

    const handleClear = () => {
        useFilterStore.getState().clearFilter();
    };

    const items = getFilters({
        keywords,
        chats: privateChats?.chats || [],
        bundles: activeProject?.bundles.filter((bundle) => bundle.is_public) || [],
    });

    const isDisabled = location.pathname !== ROUTES_URL.FEED;

    return (
        <LoadingWrapper isLoading={isLoadingKeywords}>
            <Wrapper
                elevation={0}
                square={true}
            >
                <Container
                    elevation={0}
                    isDisabled={isDisabled}
                    sx={{
                        height: open ? '100%' : '70px',
                        overflow: open && !isDisabled ? 'auto' : 'hidden',
                    }}
                >
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Typography
                            fontWeight="600"
                            variant="h4"
                        >
                            Фильтры
                        </Typography>
                        <Box
                            ml="auto"
                            title="Очистить"
                        >
                            <IconButton onClick={handleClear}>
                                <Remove />
                            </IconButton>
                        </Box>

                        <IconButton onClick={handleChangeOpen}>
                            <Sidebar />
                        </IconButton>
                    </Box>

                    <AIFilter />

                    {items.map((item) => {
                        if (item.options[0].items.length === 0) return null;

                        return (
                            <FilterItem
                                key={item.title}
                                {...item}
                            />
                        );
                    })}
                </Container>
            </Wrapper>
        </LoadingWrapper>
    );
}

const Wrapper = styled(Paper)(() => ({
    width: '100%',
    top: 0,
    position: 'sticky',
    height: '100dvh',
    padding: '1rem 1rem 1rem 0',
}));

const Container = styled(Paper)<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overscrollBehavior: 'contain',
    padding: theme.spacing(2),
    borderRadius: 24,
    position: 'relative',
    '&:after': {
        content: isDisabled ? '""' : 'none',
        display: 'block',
        position: 'absolute',
        zIndex: theme.zIndex.modal,
        inset: 0,
        background: theme.palette.background.default,
        opacity: '0.7',
    },
}));
