export const ROUTES_URL = {
    LOGIN: '/login',
    REGISTER: '/register',
    RESTORE_PASSWORD: '/restore_password',
    CREATE_PASSWORD: '/create_password',
    CHANGE_PASSWORD: '/change_password',
    CHANGE_EMAIL: '/change_email',
    BEGIN_WORK: '/begin_work',
    BEGIN_WORK_SUBSCRIPTIONS: '/begin_work/subscriptions',
    BEGIN_WORK_DESCRIPTION: '/begin_work/description',
    BEGIN_WORK_COLLECTIONS: '/begin_work/collections',
    BEGIN_WORK_CHATS_AND_CHANNELS: '/begin_work/chats_and_channels',
    BEGIN_WORK_KEYWORDS: '/begin_work/keywords',
    BEGIN_WORK_CREATE_PROJECT: '/begin_work/create_project',
    BEGIN_WORK_CREATE_PROJECT_FINISH: '/begin_work/create_project/done',
    NO_SIGNAL: '/offline',
    WELCOME_SCREEN: '/welcome_page',
    SCANING_CHANNELS: '/scaning_channels',
    SUBSCRIPTIONS: '/subscriptions',
    PAYMENT: '/payment',
    CHATS_AND_CHANNELS: '/chats_and_channels',
    KEYWORDS: '/keywords',
    COLLECTIONS: '/collections',
    CREATE_PROJECT: '/create_project',
    CREATE_PROJECT_SELECT_CHANNELS: '/create_project/select_channels',
    CREATE_PROJECT_SELECT_COLLECTIONS: '/create_project/select_collections',
    CREATE_PROJECT_FINISH: '/create_project/done',
    FEED: '/feed',
    MY_TARIFF: '/tariff',
    FAVORITES: '/favorites',
    NOT_FOUND: '/error/404',
} as const;

export type TRoutesPath = (typeof ROUTES_URL)[keyof typeof ROUTES_URL];

export type TRoutesKeys = keyof typeof ROUTES_URL;
