import { Box, Chip } from '@mui/material';

import ChevroneUpAndDows from 'src/icons/ChevroneUpAndDows';
import { openBottomSheet } from 'src/components/BottomSheet';
// import Remove from 'src/icons/Remove';
// import useFilterStore from 'src/stores/filter';

import DateSheet from '../DateSheet';
import ChannelsSheet from '../ChannelsSheet';
import KeywordsSheet from '../KeywordsSheet';
import BundlesSheet from '../BundlesSheet';
import { AIIcon } from 'src/icons/AIIcon';

import AISheet from '../AISheet';
// import CitiesSheet from '../CitiesSheet';

export default function FeedFilter() {
    // const showCities = () => {
    //     EventService.dispatch('OPEN_BOTTOM_SHEET', <CitiesSheet />);
    // };
    const showAI = () => {
        openBottomSheet(<AISheet />);
    };

    const showCollections = () => {
        openBottomSheet(<ChannelsSheet />);
    };

    const showKeywords = () => {
        openBottomSheet(<KeywordsSheet />);
    };

    const showDate = () => {
        openBottomSheet(<DateSheet />);
    };

    const showBundles = () => {
        openBottomSheet(<BundlesSheet />);
    };

    // const handleClear = () => {
    //     useFilterStore.getState().clearFilter();
    // };

    const FILTERS = [
        {
            label: `Al ChatGPT`,
            onClick: showAI,
        },
        {
            label: 'Дата',
            onClick: showDate,
        },
        {
            label: 'Подборки',
            onClick: showBundles,
        },
        {
            label: 'Каналы',
            onClick: showCollections,
        },
        {
            label: 'Слова',
            onClick: showKeywords,
        },
    ];

    return (
        <Box
            mt={1.5}
            mb={1.5}
            display="flex"
            gap={1}
            alignItems="center"
            pb={0.5}
            sx={{
                overflowX: 'auto',

                '&::-webkit-scrollbar': {
                    height: '2px',
                },
            }}
        >
            {FILTERS.map((filter) => (
                <Chip
                    key={filter.label}
                    label={filter.label}
                    variant="outlined"
                    component="div"
                    icon={
                        filter.label === 'Al ChatGPT' ? (
                            <Box pl={0.8}>
                                <AIIcon />
                            </Box>
                        ) : undefined
                    }
                    deleteIcon={
                        filter.label !== 'Al ChatGPT' ? (
                            <Box>
                                <ChevroneUpAndDows />
                            </Box>
                        ) : (
                            <></>
                        )
                    }
                    onClick={filter.onClick}
                    onDelete={filter.onClick}
                    sx={{
                        backgroundColor: '#fff',
                    }}
                    clickable
                />
            ))}

            {/* <Box
                ml="auto"
                title="Очистить"
            >
                <IconButton onClick={handleClear}>
                    <Remove />
                </IconButton>
            </Box> */}
        </Box>
    );
}
