import { useState, forwardRef } from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
} from '@mui/material';
import Eye from 'src/icons/Eye';
import Lock from 'src/icons/Lock';

type PasswordFieldProps = {
    label?: string;
    name: string;
    value: string;
    error?: string;
    placeholder?: string;
};

const PasswordField = forwardRef(({ label, name, error, value, placeholder = '', ...otherProps }: PasswordFieldProps, ref) => {
    const [show, setShow] = useState(false);
    const handleTogglePassword = () => {
        setShow((prev) => !prev);
    };

    return (
        <FormControl
            fullWidth
            error={!!error}
        >
            <FormLabel>
                <Box
                    ml={1}
                    mb={1}
                >
                    <Typography
                        color="primary"
                        variant="body1"
                    >
                        {label}
                    </Typography>
                </Box>
            </FormLabel>
            <TextField
                ref={ref as any}
                type={show ? 'text' : 'password'}
                name={name}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleTogglePassword}>
                                    <Eye open={!show} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
                placeholder={placeholder}
                value={value}
                error={!!error}
                {...otherProps}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
});

export default PasswordField;
