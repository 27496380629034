export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4318_33537)">
            <g clipPath="url(#clip1_4318_33537)">
                <path d="M3.5 5C3.5 3.61929 4.61929 2.5 6 2.5H16C17.3807 2.5 18.5 3.61929 18.5 5V13.5076C18.5 13.8755 18.5451 14.242 18.6344 14.599L19.8937 19.6362C20.1303 20.5829 19.4143 21.5 18.4384 21.5H8.12311C6.51707 21.5 5.11713 20.407 4.72761 18.8489L3.6045 14.3565C3.5351 14.0788 3.5 13.7937 3.5 13.5076V5Z" stroke="#1F1F1F" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 6.5C15 6.77614 14.7761 7 14.5 7C14.2239 7 14 6.77614 14 6.5C14 6.22386 14.2239 6 14.5 6C14.7761 6 15 6.22386 15 6.5ZM16 6.5C16 7.32843 15.3284 8 14.5 8C13.6716 8 13 7.32843 13 6.5C13 5.67157 13.6716 5 14.5 5C15.3284 5 16 5.67157 16 6.5ZM14.5 12H7V11H14.5C14.7761 11 15 11.2239 15 11.5C15 11.7761 14.7761 12 14.5 12ZM12.5 16H8V15H12.5C12.7761 15 13 15.2239 13 15.5C13 15.7761 12.7761 16 12.5 16Z" fill="#1F1F1F" />
        </g>
        <defs>
            <clipPath id="clip0_4318_33537">
                <rect width="24" height="24" fill="white" />
            </clipPath>
            <clipPath id="clip1_4318_33537">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

)