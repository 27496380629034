export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2H11.6C8.23969 2 6.55953 2 5.27606 2.65396C4.14708 3.2292 3.2292 4.14708 2.65396 5.27606C2 6.55953 2 8.23969 2 11.6V12.4C2 15.7603 2 17.4405 2.65396 18.7239C3.2292 19.8529 4.14708 20.7708 5.27606 21.346C6.55953 22 8.23969 22 11.6 22H12.4C15.7603 22 17.4405 22 18.7239 21.346C19.8529 20.7708 20.7708 19.8529 21.346 18.7239C22 17.4405 22 15.7603 22 12.4V12H21V12.4C21 14.0967 20.9992 15.309 20.9216 16.2594C20.8449 17.198 20.6982 17.7927 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.7927 20.6982 17.198 20.8449 16.2594 20.9216C15.309 20.9992 14.0967 21 12.4 21H11.6C9.90334 21 8.69099 20.9992 7.74064 20.9216C6.80197 20.8449 6.20731 20.6982 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3.30179 17.7927 3.15512 17.198 3.07842 16.2594C3.00078 15.309 3 14.0967 3 12.4V11.6C3 9.90334 3.00078 8.69099 3.07842 7.74064C3.15512 6.80197 3.30179 6.20731 3.54497 5.73005C4.02433 4.78924 4.78924 4.02433 5.73005 3.54497C6.20731 3.30179 6.80197 3.15512 7.74064 3.07842C8.69099 3.00078 9.90334 3 11.6 3H12V2Z"
                    fill="#9FA9BC"
                />
                <path
                    d="M21 3L13 11"
                    stroke="#1F1F1F"
                />
                <path
                    d="M15 2.5H20.5C21.0523 2.5 21.5 2.94772 21.5 3.5V9"
                    stroke="#1F1F1F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);
