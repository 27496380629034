import { Box } from '@mui/material';

import useCollectionsStore from 'src/stores/collections';
import KeywordsService from 'src/services/KeywordsService';
import useKeywordStore from 'src/stores/keywords';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import FilterItem from '../FeedFilter/Components/FilterItem';
import { getChatsFilter } from '../FeedFilter/utils';
import BottomSheetHeader from '../BottomSheetHeader';

export default function ChannelsSheet() {
    const keywords = useKeywordStore(({ keywords }) => keywords);

    const { isLoading: isLoadingKeywords } = useQuery({
        queryKey: KeywordsService.queryKey,
        queryFn: useKeywordStore.getState().fetchKeywords,
        onError: () => {
            enqueueSnackbar({ message: 'Ошибка получения ключевых слов', variant: 'error' });
        },
        enabled: keywords.length < 1,
    });

    const { privateCollection } = useCollectionsStore(
        ({ privateCollection }) => ({ privateCollection })
    );

    if (isLoadingKeywords) {
        return null;
    }

    const item = getChatsFilter(privateCollection.chats, true);

    return (
        <Box px={1}>
            <BottomSheetHeader title="Каналы и чаты" />
            <FilterItem
                key={item.title}
                {...item}
            />
        </Box>
    );
}
