export const StarIcon = () => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5547 6.42925L13.6708 6.66551L13.9312 6.70352L17.0565 7.15969C17.8369 7.2736 18.3879 7.35452 18.7904 7.45124C19.2007 7.54983 19.3611 7.64505 19.4353 7.7232C19.6427 7.94168 19.7405 8.24198 19.7016 8.54067C19.6877 8.64752 19.6142 8.81897 19.3407 9.14034C19.0724 9.45562 18.6749 9.84555 18.1113 10.3974L15.8426 12.6187L15.6555 12.802L15.6996 13.0601L16.2328 16.1831C16.3661 16.9639 16.4598 17.5154 16.4923 17.9299C16.5255 18.3525 16.4842 18.5351 16.4327 18.6297C16.2884 18.8948 16.0321 19.0804 15.7353 19.135C15.6293 19.1545 15.443 19.1368 15.0517 18.9737C14.668 18.8137 14.1731 18.5529 13.4727 18.183L10.6951 16.7162L10.4616 16.5929L10.2281 16.7162L7.45059 18.183C6.75015 18.5529 6.25527 18.8137 5.87154 18.9737C5.48025 19.1368 5.29395 19.1545 5.18795 19.135C4.89116 19.0804 4.63488 18.8948 4.49057 18.6297C4.43903 18.5351 4.39779 18.3525 4.43095 17.9299C4.46346 17.5154 4.55712 16.9639 4.69044 16.1831L5.22368 13.0601L5.26776 12.802L5.08062 12.6187L2.81191 10.3974C2.24835 9.84555 1.85082 9.45562 1.58252 9.14034C1.30904 8.81897 1.2355 8.64752 1.2216 8.54067C1.18273 8.24198 1.28057 7.94168 1.48793 7.7232C1.56211 7.64505 1.72253 7.54983 2.13284 7.45124C2.53536 7.35452 3.0863 7.2736 3.86676 7.15969L6.992 6.70352L7.25247 6.66551L7.36855 6.42925L8.75633 3.60466C9.10655 2.89185 9.35437 2.3886 9.57135 2.0327C9.79266 1.66968 9.93331 1.5457 10.0306 1.49924C10.3032 1.36901 10.6201 1.36901 10.8927 1.49924C10.9899 1.5457 11.1306 1.66968 11.3519 2.0327C11.5689 2.3886 11.8167 2.89186 12.1669 3.60466L13.5547 6.42925Z"
                stroke="#1F1F1F"
            />
        </svg>
    );
};
