export default {
    fontFamily: '"Inter", system-ui',
    h1: {
        fontWeight: 900,
        fontSize: 32,
        lineHeight: '40px',
    },
    h2: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '32px',
    },
    h3: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
    },
    h4: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
    },
    body1: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
    },
    body2: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
    },
    button: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '20px',
    },
    caption: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
    },
    overline: {
        fontWeight: 500,
        fontSize: 11,
        lineHeight: '16px',
    },
};
