import { create } from 'zustand';

type AppStore = {
    accordionMenu: boolean;
    setAccordionMenu: (value: boolean) => void;
};

export default create<AppStore>((set) => ({
    accordionMenu: false,
    setAccordionMenu: (value) => set({ accordionMenu: value }),
}));
