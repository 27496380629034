import globalAgent from 'src/agent';
import qs from 'qs';
import { URLS } from 'src/constants/urls';
import type { AxiosPromise } from 'axios';
import type { IMessagesResponse, IMessagesRequest } from 'src/types/Message';

export const getMessages = (params: IMessagesRequest):AxiosPromise<IMessagesResponse> => {
    const _params = qs.stringify({...params }, { skipNulls: true, addQueryPrefix: true });
    return globalAgent.get(`${URLS.MESSAGES}${_params}`);
};

export const toggleFavorite = (id: number, flag: boolean):AxiosPromise<unknown> => {
    return globalAgent.patch(`${URLS.MESSAGES}${id}/`, { is_favorite: flag });
};

export const removeMessage = (id: number):AxiosPromise<unknown> => {
    return globalAgent.delete(`${URLS.MESSAGES}${id}/`);
};
