import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useQuery, useInfiniteQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import InfinityScroll from 'src/components/InfinityScroll';
import FeedFilter from 'src/widgets/FeedFilter';
// import ProjectsTabs from 'src/widgets/ProjectsTabs';
import { LayoutPaper, DesktopLayout } from 'src/layout';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import useKeywordsStore from 'src/stores/keywords';
import useMessageStore from 'src/stores/messages';
import useChatsStore from 'src/stores/chats';
import useFilterStore from 'src/stores/filter';
import { FILTER_IDS_MESSAGE_DATE } from 'src/widgets/FeedFilter/utils';
import { ROUTES_URL } from 'src/constants/routes';

import Empty from 'src/components/Empty';

import Loader from 'src/components/Loading/Loader';

import Title from 'src/components/Title';

import ArticlesGroup from './components/ArticlesGroup';

import Card from './components/Card';

import type { IMessage } from 'src/types/Message';
import { MonitoringStartIcon } from 'src/icons/MonitoringStartIcon';
import { useAIFilter } from 'src/providers/AIFilterProvider';

const Feed = () => {
    const { fetchMessages, messages, clear, count } = useMessageStore(({ fetchMessages, messages, count, clear }) => ({
        fetchMessages,
        messages,
        clear,
        count,
    }));

    const { pathname } = useLocation();
    const {
        date = [],
        bundles,
        keywords,
        privateChats,
        cities,
        clearFilter,
    } = useFilterStore(({ date, bundles, keywords, privateChats, cities, clearFilter }) => ({
        date,
        bundles,
        keywords,
        privateChats,
        cities,
        clearFilter,
    }));

    const { fetchKeywords } = useKeywordsStore(({ fetchKeywords }) => ({ fetchKeywords }));
    const { fetchChats } = useChatsStore(({ fetchChats }) => ({ fetchChats }));
    const isMobile = useIsMobileSize();

    const isFavoriteOnly = pathname === ROUTES_URL.FAVORITES;

    const filterSnapshot = JSON.stringify({
        privateChats,
        bundles,
        keywords,
        date,
        cities,
    });

    const [filteredMessages, setFilteredMessages] = useState<IMessage[]>(messages);

    const { aiFilter } = useAIFilter();

    useEffect(() => {
        if (aiFilter) {
            setFilteredMessages(messages.filter((message) => message.is_lead));
        } else {
            setFilteredMessages(messages);
        }
    }, [aiFilter, messages]);

    const getDateFrom = () => {
        if (date[0] === FILTER_IDS_MESSAGE_DATE.ALL) {
            return undefined;
        }

        if (date[0] === FILTER_IDS_MESSAGE_DATE.WEEK) {
            return dayjs().startOf('week').format('YYYY-MM-DD');
        }

        if (date[0] === FILTER_IDS_MESSAGE_DATE.MONTH) {
            return dayjs().startOf('month').format('YYYY-MM-DD');
        }
    };

    const getDateTo = () => {
        if (date[0] === FILTER_IDS_MESSAGE_DATE.ALL) {
            return undefined;
        }

        if (date[0] === FILTER_IDS_MESSAGE_DATE.WEEK) {
            return dayjs().endOf('week').format('YYYY-MM-DD');
        }

        if (date[0] === FILTER_IDS_MESSAGE_DATE.MONTH) {
            return dayjs().endOf('month').format('YYYY-MM-DD');
        }
    };

    useEffect(() => {
        return () => {
            clear();
            clearFilter();
        };
    }, []);

    useEffect(() => {
        clear();
    }, [filterSnapshot]);

    const { isLoading: isLoadingKeywords } = useQuery({
        queryKey: ['fetch-keywords'],
        queryFn: fetchKeywords,
        onError: () => {
            enqueueSnackbar({ message: 'Ошибка получения ключевых слов', variant: 'error' });
        },
    });

    const { isLoading: isLoadingChats } = useQuery({
        queryKey: ['fetch-chats'],
        queryFn: fetchChats,
        onError: () => {
            enqueueSnackbar({ message: 'Ошибка получения чатов', variant: 'error' });
        },
    });

    const {
        isLoading: isLoadingMessage,
        isFetching: isFetchingMessage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['fetch-message', filterSnapshot],
        queryFn: ({ pageParam }) => {
            return fetchMessages({
                limit: 20,
                offset: pageParam,
                bundles: (bundles || []).join(','),
                chat_ids: (privateChats || []).join(','),
                keywords: (keywords || []).join(','),
                dateFrom: getDateFrom(),
                dateTo: getDateTo(),
                is_favorite: isFavoriteOnly,
            });
        },
        getNextPageParam: (lastPage, pages) => {
            const { next } = lastPage;
            return next ? pages.length * 20 : false;
        },
    });

    const isLoading = isLoadingChats || isLoadingKeywords;

    const groupedMessages = filteredMessages.reduce(
        (acc, message) => {
            const dateKey = dayjs(message.date).format('DD.MM.YYYY');

            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }

            acc[dateKey].push(message);

            return acc;
        },
        { [dayjs().format('DD.MM.YYYY')]: [] } as Record<string, IMessage[]>
    );

    const renderGroups = () => {
        if (isFavoriteOnly) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                >
                    {filteredMessages.map((message) => (
                        <Card
                            key={message.id}
                            body={message}
                        />
                    ))}
                </Box>
            );
        }

        const keys = Object.keys(groupedMessages);

        return keys.map((key) => (
            <ArticlesGroup
                key={key}
                groupDate={key}
                list={groupedMessages[key]}
            />
        ));
    };

    const renderContent = () => {
        if (isLoading) {
            return null;
        }

        if (isLoadingMessage) {
            return (
                <Loader
                    isOpen
                    isLocal
                    position="relative"
                    height={200}
                    sx={{
                        background: 'none',
                    }}
                />
            );
        }
        return (
            <>
                {isFavoriteOnly && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={20 / 8}
                    >
                        <Title>Избранное</Title>
                        <Typography
                            variant="body2"
                            color="primary"
                        >
                            {count}
                        </Typography>
                    </Box>
                )}
                {/* <Box
                   mx={-2}
                   style={{
                       marginTop: -2,
                   }}
                   bgcolor="background.default"
               >
                   {
                       isMobile && (
                           <ProjectsTabs
                               onChange={(id) => { }}
                               selectedIndex={0}
                               chatsList={[]}
                           />
                       )
                   }
               </Box> */}
                {isMobile && !isFavoriteOnly && <FeedFilter />}

                {isFavoriteOnly && filteredMessages.length < 1 && <Empty withMainButton={false} />}
                {!isFavoriteOnly && filteredMessages.length < 1 && (
                    <Empty
                        icon={<MonitoringStartIcon />}
                        title="Запуск мониторинга"
                        text={`Обычно первые сообщения\n\n приходят в течение 24 часов`}
                        withMainButton={false}
                    />
                )}

                {filteredMessages.length > 0 && (
                    <Box mb={2}>
                        <InfinityScroll
                            isLoading={isFetchingMessage}
                            fetchNext={fetchNextPage}
                            hasNextPage={!!hasNextPage}
                        >
                            {renderGroups()}
                        </InfinityScroll>
                    </Box>
                )}
            </>
        );
    };

    if (!isMobile) {
        return (
            <LoadingWrapper isLoading={isLoading}>
                <DesktopLayout>{renderContent()}</DesktopLayout>
            </LoadingWrapper>
        );
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <LayoutPaper isWhite>{renderContent()}</LayoutPaper>
        </LoadingWrapper>
    );
};

export default Feed;
