import { Button, Typography } from '@mui/material';
import ChevroneUp from 'src/icons/ChevroneUp';
import InfoXs from 'src/icons/InfoXs';

import QustionXs from 'src/icons/QustionXs';
import RotateLeftS from 'src/icons/RotateLeftS';

import type { MouseEvent, ReactNode } from 'react';

type ButtonTextProps = {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    children: ReactNode;
    icon?: 'INFO' | 'CHEVRONE_UP' | 'QUESTION' | 'RELOAD' | null;
    disabled?: boolean;
};

export default ({ children, onClick, icon = 'INFO', disabled = false }: ButtonTextProps) => {
    let iconComponent: React.ReactNode = null;

    switch (icon) {
        case 'INFO': {
            iconComponent = <InfoXs />;

            break;
        }
        case 'CHEVRONE_UP': {
            iconComponent = <ChevroneUp />;

            break;
        }
        case 'QUESTION': {
            iconComponent = <QustionXs />;

            break;
        }

        case 'RELOAD': {
            iconComponent = <RotateLeftS />;

            break;
        }
        default: {
            iconComponent = null;
            break;
        }
    }

    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            startIcon={iconComponent}
            variant="text"
        >
            <Typography
                variant="body1"
                color={disabled ? 'textDisabled' : 'primary'}
            >
                {children}
            </Typography>
        </Button>
    );
};
