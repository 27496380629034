import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { DesktopLayout, WithoutHeader } from 'src/layout';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import UserSevice from 'src/services/UserSevice';
import ChangeEmail, { TypeChangeEmailScheme } from 'src/validations/changeEmail';

import useUserStore from 'src/stores/user';
import LoginField from 'src/components/LoginField';
import { enqueueSnackbar } from 'notistack';
import OtpField from 'src/components/OtpField';
import SendCodeAgain from 'src/widgets/SendCodeAgain';
import { history } from 'src/utils/history';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import useSessionStore from 'src/stores/session';
import FormWrapper from 'src/components/FormWrapper';

const ChangeEmailScreen = () => {
    const isMobile = useIsMobileSize();
    const { isAuthorized } = useSessionStore(({ isAuthorized }) => ({ isAuthorized }));
    const ref = useRef<HTMLButtonElement | null>(null);
    const { setEmail } = useUserStore(({ setEmail }) => ({ setEmail }));
    const [confirmedEmail, setConfirmedEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
    } = useForm<TypeChangeEmailScheme>({
        resolver: zodResolver(ChangeEmail),
    });

    const handleRefetchCode = () => {
        if (isValid) {
            const email = getValues('new_email');
            fetchChangeEmail({ new_email: email });
        }
    };

    const handleComplete = () => {
        if (ref.current) ref.current.click();
    };

    const fetchChangeEmail = (data) => {
        setIsLoading(true);

        UserSevice.changeEmail({
            new_email: data.new_email,
        })
            .then(() => {
                setConfirmedEmail(true);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                enqueueSnackbar({
                    variant: 'error',
                    message: 'Неудалось сменить email',
                });
            });
    };

    const onSubmit = (data: TypeChangeEmailScheme) => {
        if (data.code) {
            setIsLoading(true);
            UserSevice.changeEmailConfirm({
                new_email: data.new_email,
                code: data.code,
            })
                .then(() => {
                    setIsLoading(false);

                    enqueueSnackbar({
                        variant: 'success',
                        message: 'Email успешно изменен',
                    });

                    setEmail(data.new_email);

                    history.push('/');
                })
                .catch(() => {
                    setIsLoading(false);
                    enqueueSnackbar({
                        variant: 'error',
                        message: 'Неудалось сменить email. Неверный код подтверждения',
                    });
                });

            return;
        }

        fetchChangeEmail(data);
    };

    const renderContent = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {!confirmedEmail && (
                    <Box mb={2}>
                        <Controller
                            name="new_email"
                            control={control}
                            render={({ field }) => (
                                <LoginField
                                    {...field}
                                    label="Новый email"
                                    placeholder="user@sample.com"
                                    error={errors?.new_email?.message}
                                />
                            )}
                        />
                    </Box>
                )}
                {confirmedEmail && (
                    <>
                        <Box mb={2}>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field }) => (
                                    <OtpField
                                        {...field}
                                        onComplete={handleComplete}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={0.5}>
                            <SendCodeAgain
                                timer={30}
                                onClick={handleRefetchCode}
                            />
                        </Box>
                    </>
                )}
                <Box>
                    <Button
                        ref={(r) => (ref.current = r)}
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={isLoading}
                        fullWidth
                    >
                        Сменить email
                    </Button>
                </Box>
            </form>
        );
    };

    if (isAuthorized && !isMobile)
        return (
            <DesktopLayout>
                <FormWrapper
                    name="Сменить email"
                    topGutter
                >
                    {renderContent()}
                </FormWrapper>
            </DesktopLayout>
        );

    return (
        <WithoutHeader
            showBack
            showMyHeader
            title="Сменить email"
        >
            {renderContent()}
        </WithoutHeader>
    );
};

export default ChangeEmailScreen;
