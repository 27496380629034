export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
    >
        <g clipPath="url(#clip0_3767_24804)">
            <g clipPath="url(#clip1_3767_24804)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.56249 18.5C8.65214 18.5784 8.75071 18.6505 8.85822 18.715L14.9709 22.3826C15.6043 22.7626 16.3955 22.7626 17.0289 22.3826L23.1416 18.715C23.2491 18.6505 23.3477 18.5784 23.4373 18.5C24.4273 19.3658 24.3288 21.0027 23.1416 21.715L17.0289 25.3826C16.3955 25.7626 15.6043 25.7626 14.9709 25.3826L8.85822 21.715C7.67104 21.0027 7.57246 19.3658 8.56249 18.5Z"
                    fill="#E3ECF3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.56249 14.5C8.65214 14.5784 8.75071 14.6505 8.85822 14.715L14.9709 18.3826C15.6043 18.7626 16.3955 18.7626 17.0289 18.3826L23.1416 14.715C23.2491 14.6505 23.3477 14.5784 23.4373 14.5C24.4273 15.3658 24.3288 17.0027 23.1416 17.715L17.0289 21.3826C16.3955 21.7626 15.6043 21.7626 14.9709 21.3826L8.85822 17.715C7.67104 17.0027 7.57246 15.3658 8.56249 14.5Z"
                    fill="#9FA9BC"
                />
                <path
                    d="M9.37281 11.1425L15.4855 7.47489C15.8022 7.28488 16.1978 7.28488 16.5145 7.47489L22.6272 11.1425C23.2745 11.5309 23.2745 12.4691 22.6272 12.8575L16.5145 16.5251C16.1978 16.7151 15.8022 16.7151 15.4855 16.5251L9.37281 12.8575C8.72547 12.4691 8.72547 11.5309 9.37281 11.1425Z"
                    fill="#1F1F1F"
                    stroke="#1F1F1F"
                    strokeWidth="2"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3767_24804">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(4 4)"
                />
            </clipPath>
            <clipPath id="clip1_3767_24804">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(4 4)"
                />
            </clipPath>
        </defs>
    </svg>
);
