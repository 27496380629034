import { useCallback, useState } from 'react';
import { Box, Button, RadioGroup, Radio, Typography, debounce } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import CustomInputField from 'src/components/CustomInputField';

import { closeBottomSheet } from 'src/components/BottomSheet';

import BottomSheetHeader from '../BottomSheetHeader';

type SearchCityForm = {
    city: string;
};

interface SearchCityProps {
    activeCity?: string;
    cities: string[];
    onSubmit: (city: string) => void;
}

const EmptyResult = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={10}
        >
            <Box my={5}>
                <Typography
                    variant="body1"
                    color="primary"
                    textAlign="center"
                >
                    Поиск не дал результатов...
                </Typography>
            </Box>
        </Box>
    );
};

export default function SearchCity({ cities, onSubmit, activeCity }: SearchCityProps) {
    const [searchVal, setSearchVal] = useState('');
    const [filteredCities, setFilteredCities] = useState(cities);

    const { control, handleSubmit } = useForm<SearchCityForm>({
        defaultValues: {
            city: activeCity,
        },
    });

    const onSubmitHandler: SubmitHandler<SearchCityForm> = ({ city }) => {
        onSubmit(city);
        closeBottomSheet();
    };

    const onSearchChange = (value: string) => {
        setSearchVal(value);
        onFilteredCitiesChange(value);
    };

    const onFilterCitiesChangeHandler = (value: string) => {
        if (!value) {
            setFilteredCities(cities);
            return;
        }
        setFilteredCities(cities.filter((city) => city.toLowerCase().includes(value.trim().toLowerCase())));
    };

    const onFilteredCitiesChange = useCallback(debounce(onFilterCitiesChangeHandler, 300), []);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Box
                px={1}
                position="relative"
            >
                <Box mb={2}>
                    <BottomSheetHeader title="Локации" />
                </Box>
                <Box mb={1}>
                    <CustomInputField
                        value={searchVal}
                        onChange={onSearchChange}
                        placeholder="Поиск"
                    />
                </Box>
                <Box
                    border="1px solid #E3ECF3"
                    borderRadius={4}
                    height={'200px'}
                    overflow="auto"
                >
                    {filteredCities.length > 0 ? (
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup {...field}>
                                    {filteredCities.map((city, idx) => (
                                        <Box
                                            pl={2}
                                            pr={1.5}
                                            py={1}
                                            component="label"
                                            htmlFor={city}
                                            key={city}
                                            style={{ cursor: 'pointer' }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            borderBottom={idx !== cities.length - 1 ? '1px solid #E3ECF3' : 'none'}
                                        >
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                {city}
                                            </Typography>
                                            <Radio
                                                id={city}
                                                value={city}
                                            />
                                        </Box>
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    ) : (
                        <EmptyResult />
                    )}
                </Box>
                <Box mb={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Выбрать локацию
                    </Button>
                </Box>
            </Box>
        </form>
    );
}
