import { Box, Button, Checkbox, Radio, Typography, styled } from '@mui/material';

import { useState } from 'react';

import ButtonText from '../ButtonText';

const ListBox = styled(Box)(() => ({
    border: '1px solid',
    borderColor: '#E3ECF3',
    borderRadius: 16,
    width: '100%',
}));

const ListBoxItem = styled('label')(() => ({
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.75rem 1rem',
    borderBottom: '1px solid #E3ECF3',
    cursor: 'pointer',
    gridTemplateColumns: 'calc(100% - 40px) 30px',
    gap: '1rem',
    '&:last-child': {
        borderBottom: 'none',
    },
}));

type CheckboxGroupProps = {
    type?: 'radio' | 'checkbox';
    options: Array<{ id: number | string; name: string }>;
    title?: string;
    field: any;
    withClearButton?: boolean;
    allSelectedText?: string;
    maxCount?: number | null;
    subtitle?: string;
};

const styles: React.CSSProperties = {
    padding: 0,
};

const Component = ({ type, ...props }) =>
    type === 'checkbox' ? (
        <Checkbox
            style={styles}
            {...props}
        />
    ) : (
        <Radio
            style={styles}
            {...props}
        />
    );

const CheckboxGroup = ({
    allSelectedText = 'Выбрать все',
    type = 'checkbox',
    field,
    options,
    title,
    withClearButton = true,
    maxCount,
}: CheckboxGroupProps) => {
    const [collapsed, setCollapsed] = useState(true);

    const onCollapseClickHandler = () => {
        setCollapsed((prevState) => !prevState);
    };

    const optionsWithMaxCount = typeof maxCount === 'number' && collapsed ? options.slice(0, maxCount) : options;

    const handleClearClick = () => {
        field.onChange([]);
    };

    const onChangeHandler = (event: React.SyntheticEvent<Element, Event>) => {
        const target = event.target as HTMLInputElement;
        const { value, checked } = target;

        if (type === 'radio') {
            field.onChange([value]);
            return;
        }

        if (type === 'checkbox') {
            if (value === 'all') {
                field.onChange(checked ? options.map(({ id }) => String(id)) : []);
                return;
            }

            const prevValue = (field.value as Array<any>) || [];

            field.onChange(checked ? [...prevValue, value] : prevValue.filter((prevValue) => prevValue !== value));
        }
    };

    const isShowMoreButton = typeof maxCount === 'number' ? options.length > maxCount : false;

    return (
        <Box>
            {type === 'checkbox' && (
                <Box mb={0}>
                    <ListBox>
                        <ListBoxItem>
                            <Typography>{allSelectedText}</Typography>
                            <Component
                                onChange={onChangeHandler}
                                value="all"
                                checked={options.every(({ id }) =>
                                    field.value?.some((selectedId) => selectedId === id)
                                )}
                                type={type}
                            />
                        </ListBoxItem>
                    </ListBox>
                </Box>
            )}

            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mb={1}
            >
                {title && <Typography variant="h4">{title}</Typography>}
                {withClearButton && (
                    <ButtonText
                        icon={null}
                        onClick={handleClearClick}
                    >
                        Убрать все
                    </ButtonText>
                )}
            </Box>
            <ListBox>
                {optionsWithMaxCount.map(({ id, name }) => (
                    <ListBoxItem key={`${name}_${id}`}>
                        <Typography
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            title={name}
                        >
                            {name}
                        </Typography>

                        <Component
                            {...field}
                            onChange={onChangeHandler}
                            checked={[...(field.value || [])].includes(String(id))}
                            value={String(id)}
                            type={type}
                        />
                    </ListBoxItem>
                ))}
                {isShowMoreButton && (
                    <Button
                        onClick={onCollapseClickHandler}
                        fullWidth
                    >
                        <Typography
                            variant="body2"
                            color="secondary"
                        >
                            {collapsed ? 'Показать все' : 'Свернуть'}
                        </Typography>
                    </Button>
                )}
            </ListBox>
        </Box>
    );
};

export default CheckboxGroup;
