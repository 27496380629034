import { Box, Typography, FormControl, RadioGroup, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

type PaymentMethod = {
    name: string;
    value: string;
    currency: string;
};

type PaymentMethodsProps = {
    name: string;
    control: any;
    methods?: PaymentMethod[];
};

const PaymentMethods = ({ name, control, methods = [] }: PaymentMethodsProps) => {
    const isMobile = useIsMobileSize();
    const borderColor = isMobile ? '#E3ECF3' : '#CCCCCC'
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl
                    style={{
                        border: '1px solid',
                        borderColor,
                        borderRadius: '16px',
                        width: '100%',
                        backgroundColor: '#fff'
                    }}
                >
                    <RadioGroup {...field}>
                        {methods.map((method, idx) => (
                            <Box
                                key={method.value}
                                display="block"
                                component={'label'}
                                borderBottom={idx === methods.length - 1 ? 'none' : `1px solid ${borderColor}`}
                                p={1}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                    >
                                        {method.name}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="6px"
                                    >
                                        <Box mr={-1}>
                                            <Typography
                                                variant="body2"
                                                color="secondary"
                                                textTransform="uppercase"
                                            >
                                                {method.currency} 
                                            </Typography>
                                        </Box>
                                        <Radio value={method.value} />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

export default PaymentMethods;
