export const AIIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 8.26116 14.9857 8.51899 14.9578 8.77273C14.8801 8.58316 14.7023 8.4439 14.4869 8.42356L13.917 8.36976C13.3113 8.31259 13.0085 8.284 12.746 8.22293C11.2692 7.87925 10.1137 6.73013 9.76185 5.25527C9.69932 4.99317 9.66905 4.6905 9.60852 4.08518L9.60852 4.08515L9.54975 3.49752C9.52151 3.21508 9.28384 3 9 3C8.71616 3 8.47849 3.21508 8.45025 3.49752L8.39148 4.08515L8.39148 4.08518C8.33095 4.6905 8.30068 4.99317 8.23815 5.25527C7.88629 6.73013 6.73078 7.87925 5.25399 8.22293C4.99155 8.284 4.68871 8.31259 4.08306 8.36976L4.08304 8.36976L3.51315 8.42356C3.22226 8.45102 3 8.69525 3 8.98743C3 9.27841 3.22049 9.52205 3.51002 9.551L4.08311 9.60831C4.70818 9.67082 5.02071 9.70207 5.29138 9.76795C6.74418 10.1215 7.87848 11.2558 8.23205 12.7086C8.29793 12.9793 8.32918 13.2918 8.39169 13.9169L8.39169 13.9169L8.45025 14.5025C8.47092 14.7092 8.6038 14.8799 8.78486 14.9565C8.52721 14.9852 8.26533 15 8 15ZM9.35793 14.8684C9.46338 14.7787 9.53503 14.6497 9.54975 14.5025L9.60831 13.9169C9.67082 13.2918 9.70207 12.9793 9.76795 12.7086C10.1215 11.2558 11.2558 10.1215 12.7086 9.76795C12.9793 9.70207 13.2918 9.67082 13.9169 9.60831L14.49 9.551C14.6438 9.53562 14.7782 9.45963 14.8703 9.34813C14.3277 12.1296 12.138 14.3219 9.35793 14.8684Z"
                fill="url(#paint0_linear_5933_3846)"
            />
            <path
                d="M3.82184 0.239457C3.7792 0.0973307 3.64838 0 3.5 0C3.35162 0 3.2208 0.0973307 3.17816 0.239457L2.74756 1.6748C2.65707 1.97642 2.61183 2.12723 2.52905 2.25145C2.45577 2.36142 2.36142 2.45577 2.25145 2.52905C2.12723 2.61183 1.97642 2.65707 1.6748 2.74756L0.239457 3.17816C0.0973306 3.2208 0 3.35162 0 3.5C0 3.64838 0.0973306 3.7792 0.239457 3.82184L1.59356 4.22807C1.92337 4.32701 2.08828 4.37648 2.22058 4.46933C2.33761 4.55146 2.43567 4.65774 2.50814 4.78098C2.59007 4.9203 2.62614 5.08865 2.69829 5.42534L3.19762 7.75555C3.22817 7.89813 3.35418 8 3.5 8C3.64582 8 3.77183 7.89813 3.80238 7.75555L4.30171 5.42534C4.37386 5.08865 4.40993 4.9203 4.49186 4.78098C4.56433 4.65774 4.66239 4.55146 4.77942 4.46933C4.91172 4.37648 5.07663 4.32701 5.40644 4.22807L6.76054 3.82184C6.90267 3.7792 7 3.64838 7 3.5C7 3.35162 6.90267 3.2208 6.76054 3.17816L5.3252 2.74756C5.02358 2.65707 4.87277 2.61183 4.74855 2.52905C4.63858 2.45577 4.54423 2.36142 4.47095 2.25145C4.38817 2.12723 4.34293 1.97642 4.25244 1.6748L3.82184 0.239457Z"
                fill="#7F30E4"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5933_3846"
                    x1="5.5"
                    y1="2.5"
                    x2="10"
                    y2="15"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor="#FA00FF"
                        stopOpacity="0.3"
                    />
                    <stop
                        offset="1"
                        stopColor="#FF1F00"
                        stopOpacity="0.4"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};
export const AIIconBlack = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3693_37731)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7502 21.9723V22.9748C18.4756 22.5893 23.0002 17.8231 23.0002 12C23.0002 10.581 22.7315 9.2248 22.2423 7.97944L21.271 8.24432C21.7412 9.4039 22.0002 10.6717 22.0002 12C22.0002 17.2706 17.9228 21.5888 12.7502 21.9723ZM15.9723 2.81992C14.7547 2.2924 13.4116 2 12.0002 2C6.72967 2 2.41145 6.07745 2.02793 11.25H1.02539C1.41089 5.52466 6.17716 1 12.0002 1C13.5429 1 15.0114 1.31756 16.3439 1.89086L15.9723 2.81992ZM1.65598 15.75C2.76497 18.8085 5.19176 21.2353 8.25022 22.3442V21.2731C5.74347 20.2584 3.74183 18.2567 2.72712 15.75H1.65598Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M10.0355 3.92041C10.0593 3.68175 10.2601 3.5 10.5 3.5C10.7399 3.5 10.9407 3.68175 10.9645 3.92041L11.0674 4.94877L11.0771 5.04566C11.2514 6.78995 11.3495 7.77096 11.6721 8.59932C12.3372 10.3072 13.6923 11.6548 15.4038 12.3104C16.234 12.6284 17.2155 12.7211 18.9607 12.8857L19.0577 12.8949L20.055 12.989C20.3073 13.0128 20.5 13.2246 20.5 13.478C20.5 13.7303 20.3088 13.9416 20.0577 13.9667L19.0548 14.067L18.9564 14.0769C17.1829 14.2541 16.1854 14.3538 15.3437 14.6863C13.6711 15.347 12.347 16.6711 11.6863 18.3437C11.3538 19.1854 11.2541 20.1829 11.0769 21.9564L11.067 22.0548L10.9645 23.0796C10.9407 23.3183 10.7399 23.5 10.5 23.5C10.2601 23.5 10.0593 23.3183 10.0355 23.0796L9.93297 22.0548L9.92313 21.9564C9.74587 20.1829 9.64617 19.1854 9.31369 18.3437C8.653 16.6711 7.32887 15.347 5.65626 14.6863C4.81458 14.3538 3.81708 14.2541 2.04365 14.0769L1.9452 14.067L0.94229 13.9667C0.691207 13.9416 0.5 13.7303 0.5 13.478C0.5 13.2246 0.692744 13.0128 0.945001 12.989L1.94232 12.8949C1.97488 12.8918 2.00719 12.8888 2.03923 12.8857C3.78448 12.7211 4.76602 12.6284 5.59617 12.3104C7.30766 11.6548 8.66284 10.3072 9.32793 8.59932C9.65053 7.77096 9.74858 6.78995 9.92293 5.04566L9.93262 4.94877L10.0355 3.92041Z"
                    stroke="#1F1F1F"
                />
                <path
                    d="M17.534 0.363804C17.5875 0.149994 17.7796 0 18 0C18.2204 0 18.4125 0.149994 18.466 0.363803L19.1507 3.10299C19.2777 3.61092 19.3412 3.86489 19.4735 4.07154C19.5904 4.25434 19.7457 4.40958 19.9285 4.52654C20.1351 4.65878 20.3891 4.72227 20.897 4.84925L23.6362 5.53405C23.85 5.5875 24 5.77961 24 6C24 6.22039 23.85 6.4125 23.6362 6.46595L20.897 7.15075C20.3891 7.27773 20.1351 7.34122 19.9285 7.47346C19.7457 7.59042 19.5904 7.74566 19.4735 7.92846C19.3412 8.13511 19.2777 8.38908 19.1507 8.89701L18.466 11.6362C18.4125 11.85 18.2204 12 18 12C17.7796 12 17.5875 11.85 17.534 11.6362L16.8493 8.89701C16.7223 8.38908 16.6588 8.13511 16.5265 7.92846C16.4096 7.74566 16.2543 7.59042 16.0715 7.47346C15.8649 7.34122 15.6109 7.27773 15.103 7.15075L12.3638 6.46595C12.15 6.4125 12 6.22039 12 6C12 5.77961 12.15 5.5875 12.3638 5.53405L15.103 4.84925C15.6109 4.72227 15.8649 4.65878 16.0715 4.52654C16.2543 4.40958 16.4096 4.25434 16.5265 4.07154C16.6588 3.86489 16.7223 3.61092 16.8493 3.10299L17.534 0.363804Z"
                    fill="#1F1F1F"
                />
            </g>
            <defs>
                <clipPath id="clip0_3693_37731">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
