import { Sheet } from 'react-modal-sheet';
import { useState, useEffect } from 'react';
import EventService from 'src/services/EventService';

import type { ReactNode } from 'react';

export const closeBottomSheet = () => EventService.dispatch('CLOSE_BOTTOM_SHEET');

export const openBottomSheet = (component: ReactNode) => EventService.dispatch('OPEN_BOTTOM_SHEET', component);

export default function BottomSheet() {
    const [component, setComponent] = useState<ReactNode>(null);

    const handleClose = () => {
        setComponent(null);
    };

    useEffect(() => {
        const subscriber = EventService.subscribe('OPEN_BOTTOM_SHEET', (_, detail) => {
            setComponent(detail);
        });

        const subscriber2 = EventService.subscribe('CLOSE_BOTTOM_SHEET', () => {
            setComponent(null);
        });

        return () => {
            subscriber.unsubscribe();
            subscriber2.unsubscribe();
        };
    }, []);

    useEffect(() => {}, []);

    return (
        <Sheet
            isOpen={!!component}
            onClose={handleClose}
            detent="content-height"
            disableScrollLocking={false}
            style={{
                pointerEvents: component ? undefined : 'none',
            }}
        >
            <Sheet.Container
                style={{
                    overflow: 'auto',
                    overscrollBehavior: 'contain',
                }}
            >
                <Sheet.Header style={{
                    height: 20,
                    width: '100%',
                    maxWidth: 600,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }} />
                <Sheet.Content
                    style={{
                        width: '100%',
                        maxWidth: 600,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >{component}</Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    );
}
