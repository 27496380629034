import { Box, Typography, Button, TextField, InputAdornment, styled, Stack, CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import { z } from 'zod';
import { IBeginWorkOnSuccessArgs } from 'src/types';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Title from 'src/components/Title';

import { UserIcon } from 'src/icons/UserIcon';
import { UsersIcon } from 'src/icons/UsersIcon';
import { TgArrowIcon } from 'src/icons/TgArrowIcon';
import { CaseIcon } from 'src/icons/CaseIcon';
import UserSevice from 'src/services/UserSevice';
import useUserStore from 'src/stores/user';
import useProjectsStore from 'src/stores/projects';
import { AIIcon } from 'src/icons/AIIcon';
import { IUserDescription } from 'src/types/User';
import { TUpdateTProject } from 'src/types/Projects';
import ProjectsService from 'src/services/ProjectsService';

const ProfileSchema = z.object({
    first_name: z.string().optional(),
    tg_account: z.string().optional(),
    business_description: z.string().max(150, 'Не более 150 символов'),
    audience_description: z.string().max(150, 'Не более 150 символов'),
});

export const MaxChars = ({
    isError,
    charCount,
    maxChars,
}: {
    isError: boolean;
    charCount: number;
    maxChars: number;
}) => {
    return (
        <Stack
            gap="2px"
            direction="row"
            alignItems="center"
            sx={{ mt: 0.5 }}
        >
            {!isError && <AIIcon />}

            <Typography
                variant="body2"
                sx={{
                    background: isError ? 'none' : 'linear-gradient(90deg, #FF88C0, #9FA9BC)',
                    WebkitBackgroundClip: isError ? 'none' : 'text',
                    color: isError ? 'error.main' : 'transparent',
                }}
            >
                {isError ? `Превышено количество символов (максимум ${maxChars})` : `${charCount}/${maxChars}`}
            </Typography>
        </Stack>
    );
};

export interface IChatsAndChannelsScreenProps {
    onSuccess?: ({ skiped }: IBeginWorkOnSuccessArgs) => void;
}

export default function DescribeYourBusiness({ onSuccess }: IChatsAndChannelsScreenProps) {
    useQuery('projects', ProjectsService.getProjects, {
        onSuccess: (projects) => useProjectsStore.getState().setProjects(projects),
        onError: () => enqueueSnackbar('Ошибка при загрузке проектов', { variant: 'error' }),
    });

    const userInfo = useUserStore((state) => ({
        first_name: state.first_name,
        tg_account: state.tg_account,
        business_description: state.business_description,
        isOnboardingCompleted: state.isOnboardingCompleted,
    }));

    const projectInfo = useProjectsStore((project) => project);

    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
        watch,
    } = useForm({
        defaultValues: {
            first_name: userInfo.first_name || '',
            tg_account: userInfo.tg_account || '',
            business_description: userInfo.business_description || '',
            audience_description: projectInfo.projects[0]?.audience_description || '',
        },
        resolver: zodResolver(ProfileSchema),
        mode: 'onChange',
        resetOptions: {
            keepIsSubmitted: true,
        },
    });

    const formValues = watch();

    const queryClient = useQueryClient();

    const { mutate: updateDescription, isLoading: isUpdateDescriptionLoading } = useMutation(
        async (data: IUserDescription) => {
            await UserSevice.updateDescription(data);
            queryClient.invalidateQueries('user');
        }
    );

    const { mutate: updateProject, isLoading: isUpdateProjectLoading } = useMutation(async (data: TUpdateTProject) => {
        await useProjectsStore.getState().updateProject(data);
        queryClient.invalidateQueries('projects');
    });

    const onSubmit = async (data: IUserDescription & TUpdateTProject) => {
        try {
            await Promise.all([
                updateDescription({
                    tg_account: data.tg_account,
                    business_description: data.business_description,
                    first_name: data.first_name,
                }),
                updateProject({
                    audience_description: data.audience_description,
                }),
            ]);
            enqueueSnackbar('Все данные успешно обновлены', { variant: 'success' });
            if (onSuccess) onSuccess({ skiped: false });
        } catch (error) {
            enqueueSnackbar('Ошибка при обновлении данных', { variant: 'error' });
        }
    };

    const isFormChanged = Object.keys(formValues).some(
        (key) => formValues[key] !== (userInfo[key] ?? projectInfo.activeProject?.[key] ?? '')
    );
    const isLoading = isUpdateDescriptionLoading || isUpdateProjectLoading;
    const isDisabled = isLoading || !isFormChanged || !isValid;

    return (
        <LoadingWrapper isLoading={false}>
            <Box
                pt={2}
                minHeight="100%"
                display="flex"
                flexDirection="column"
            >
                <Box mb={1.5}>
                    <Title>{userInfo.isOnboardingCompleted ? 'Профиль' : 'Опишите ваш бизнес'}</Title>
                </Box>
                <Box
                    maxWidth="80%"
                    mb={20 / 8}
                >
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        {userInfo.isOnboardingCompleted
                            ? 'Информация про ваш бизнес и целевых клиентов важна для точного определения целевых сообщений с помощью ChatGPT'
                            : 'Введите контактные данные и информацию про ваш бизнес для определения целевых клиентов с помощью ChatGPT'}
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Stack gap={1}>
                            <Typography
                                component="label"
                                htmlFor="first_name"
                            >
                                Как Вас зовут?
                            </Typography>
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field }) => (
                                    <CustomTextField
                                        {...field}
                                        id="first_name"
                                        fullWidth
                                        placeholder="Введите ваше имя"
                                        error={!!errors.first_name}
                                        helperText={errors.first_name?.message}
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <UserIcon />
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Stack gap={1}>
                            <Typography
                                component="label"
                                htmlFor="tg_account"
                            >
                                Ваш Telegram-аккаунт для связи
                            </Typography>
                            <Controller
                                name="tg_account"
                                control={control}
                                render={({ field }) => (
                                    <CustomTextField
                                        {...field}
                                        id="tg_account"
                                        placeholder="@username"
                                        fullWidth
                                        error={!!errors.tg_account}
                                        helperText={errors.tg_account?.message}
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <TgArrowIcon />
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Stack gap={1}>
                            <Typography
                                component="label"
                                htmlFor="business_description"
                            >
                                Чем занимается ваш бизнес?
                            </Typography>
                            <Controller
                                name="business_description"
                                control={control}
                                render={({ field }) => {
                                    const charCount = field.value.length;
                                    const isError = charCount > 150;

                                    return (
                                        <>
                                            <CustomTextField
                                                {...field}
                                                id="business_description"
                                                placeholder="Например: вы занимаетесь ремонтом квартир в Москве"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                error={isError}
                                                slotProps={{
                                                    input: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CaseIcon />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '134px',
                                                    },
                                                }}
                                            />

                                            <MaxChars
                                                isError={isError}
                                                charCount={charCount}
                                                maxChars={150}
                                            />
                                        </>
                                    );
                                }}
                            />
                        </Stack>

                        <Stack gap={1}>
                            <Typography
                                component="label"
                                htmlFor="audience_description"
                            >
                                Кто ваши целевые клиенты и какой у них запрос?
                            </Typography>
                            <Controller
                                name="audience_description"
                                control={control}
                                render={({ field }) => {
                                    const charCount = field.value.length;
                                    const isError = charCount > 150;

                                    return (
                                        <>
                                            <CustomTextField
                                                {...field}
                                                id="audience_description"
                                                placeholder="Например: люди в новостройках, интересуются контактами бригад по ремонту квартиры в Москве"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                error={isError}
                                                slotProps={{
                                                    input: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <UsersIcon />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '134px',
                                                    },
                                                }}
                                            />

                                            <MaxChars
                                                isError={isError}
                                                charCount={charCount}
                                                maxChars={150}
                                            />
                                        </>
                                    );
                                }}
                            />
                        </Stack>

                        <Box
                            display="flex"
                            alignItems="flex-end"
                            flexGrow={1}
                            pb={1}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                type="submit"
                                disabled={isDisabled}
                            >
                                {isLoading ? <CircularProgress size={20} /> : 'Сохранить'}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </LoadingWrapper>
    );
}

const CustomTextField = styled(TextField)({
    '&.MuiTextField-root': { borderRadius: '16px' },
    backgroundColor: '#fff',
});
