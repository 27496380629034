export const UserIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8ZM18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM17.7804 17.0769C17.394 17 16.9293 17 16 17H8C7.07069 17 6.60603 17 6.21964 17.0769C4.63288 17.3925 3.39249 18.6329 3.07686 20.2196C3 20.606 3 21.0707 3 22H4V21C4 19.3431 5.34315 18 7 18H17C18.6569 18 20 19.3431 20 21V22H21C21 21.0707 21 20.606 20.9231 20.2196C20.6075 18.6329 19.3671 17.3925 17.7804 17.0769Z"
                fill="#1F1F1F"
            />
        </svg>
    );
};
