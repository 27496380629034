import { Box } from '@mui/material';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import type { ReactNode } from 'react';

type CenterScreenProps = {
    children: ReactNode;
};

export default ({ children }: CenterScreenProps) => {
    const isMobile = useIsMobileSize()
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop={isMobile ? '65%' : '25%'}
        >
            {children}
        </Box>
    );
};
