import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';

import useGoTo from 'src/hooks/useGoTo';
import DescribeYourBusinessScreen from '../DescribeYourBusiness';

export default function DescribeYourBusiness() {
    const goTo = useGoTo();

    const onSuccessHandler = () => {
        updateBeginWorkStep({
            currentStep: BEGIN_WORK_STEPS_IDS.DESCRIPTION,
            onSuccess: () => {
                goTo('/');
            },
        });
    };

    return <DescribeYourBusinessScreen onSuccess={onSuccessHandler} />;
}
