export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0002 16V8H19.5002H19.4783C18.7968 8 18.2548 8.00001 17.8175 7.96428C17.3697 7.92769 16.9878 7.85116 16.6382 7.67302C16.0737 7.3854 15.6148 6.92646 15.3272 6.36197C15.149 6.01235 15.0725 5.63051 15.0359 5.18271C15.0002 4.74542 15.0002 4.2034 15.0002 3.52188V3.5V3H11.0002C10.2599 3 9.61357 3.4022 9.26776 4H8.1709C8.58273 2.83481 9.69397 2 11.0002 2H16.1718C16.7022 2 17.2109 2.21071 17.586 2.58579L20.4144 5.41421C20.7895 5.78929 21.0002 6.29799 21.0002 6.82843V16C21.0002 17.6569 19.657 19 18.0002 19H17.0002V18H18.0002C19.1048 18 20.0002 17.1046 20.0002 16ZM20.0002 6.82843V7H19.5002C18.7919 7 18.2907 6.99961 17.8989 6.9676C17.513 6.93607 17.2774 6.87635 17.0922 6.78201C16.7159 6.59027 16.4099 6.28431 16.2182 5.90798C16.1238 5.72283 16.0641 5.48724 16.0326 5.10128C16.0006 4.70948 16.0002 4.20832 16.0002 3.5V3H16.1718C16.437 3 16.6913 3.10536 16.8789 3.29289L19.7073 6.12132C19.8948 6.30886 20.0002 6.56321 20.0002 6.82843Z"
            fill="#1F1F1F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 11V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V8C4 6.89543 4.89543 6 6 6H10V6.5V6.52188C10 7.2034 9.99999 7.74542 10.0357 8.18271C10.0723 8.63051 10.1488 9.01235 10.327 9.36197C10.6146 9.92646 11.0735 10.3854 11.638 10.673C11.9877 10.8512 12.3695 10.9277 12.8173 10.9643C13.2546 11 13.7966 11 14.4781 11H14.5H15ZM15 10V9.82843C15 9.56321 14.8946 9.30886 14.7071 9.12132L11.8787 6.29289C11.6911 6.10536 11.4368 6 11.1716 6H11V6.5C11 7.20832 11.0004 7.70948 11.0324 8.10128C11.0639 8.48724 11.1236 8.72283 11.218 8.90798C11.4097 9.28431 11.7157 9.59027 12.092 9.78201C12.2772 9.87635 12.5128 9.93607 12.8987 9.9676C13.2905 9.99961 13.7917 10 14.5 10H15ZM3 8C3 6.34315 4.34315 5 6 5H11.1716C11.702 5 12.2107 5.21071 12.5858 5.58579L15.4142 8.41421C15.7893 8.78929 16 9.29799 16 9.82843V19C16 20.6569 14.6569 22 13 22H6C4.34315 22 3 20.6569 3 19V8Z"
            fill="#1F1F1F"
        />
    </svg>
);
