import { Box, TextField, InputAdornment } from '@mui/material';

import Backspace from 'src/icons/Backspace';
import Search from 'src/icons/Search';
import PenSqure from 'src/icons/PenSqure';
import Book from 'src/icons/Book';

import type { ChangeEvent } from 'react';

type CustomInputFieldProps = {
    onChange?: (value: string) => void;
    value: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    icon?: keyof typeof IconComponentMapper;
};

const IconComponentMapper = {
    search: <Search />,
    edit: <PenSqure />,
    book: <Book />,
} as const;

export default function CustomInputField({
    onChange,
    value,
    name = '',
    placeholder = '',
    disabled = false,
    icon = 'search',
}: CustomInputFieldProps) {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value);
    };

    const handleClear = () => {
        if (onChange) onChange('');
    };

    return (
        <TextField
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            fullWidth
            slotProps={{
                input: {
                    sx: {
                        background: '#fff',
                    },
                    startAdornment: IconComponentMapper[icon] && (
                        <InputAdornment position="start">{IconComponentMapper[icon]}</InputAdornment>
                    ),
                    endAdornment: value.length > 0 && (
                        <InputAdornment position="end">
                            <Box
                                display="flex"
                                alignItems="center"
                                onClick={handleClear}
                                width={19}
                                height={47}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <Backspace />
                            </Box>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
}
