import { Box, Typography } from '@mui/material';
import { useEffect, useState, useRef, memo } from 'react';
import ClockS from 'src/icons/ClockS';

const Timer = ({ timeout = 0, callback = () => {} }) => {
    const [t, setTimer] = useState(timeout);
    const ref = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        setTimer(timeout);

        if (ref.current) clearInterval(ref.current);

        ref.current = setInterval(() => {
            setTimer((prev) => {
                if (prev - 1 > 0) return (prev -= 1);

                return 0;
            });
        }, 1000);
        return () => {
            if (ref.current) clearInterval(ref.current);
        };
    }, [timeout]);

    useEffect(() => {
        if (t === 0) {
            callback();
            if (ref.current) clearInterval(ref.current);
        }
    }, [t]);

    const minutes = Math.floor(t / 60);
    const seconds = t % 60;

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            <ClockS />
            <Typography variant="body1">
                {minutes < 10 ? `0${minutes}` : minutes}:{seconds > 9 ? t % 60 : `0${seconds}`}
            </Typography>
        </Box>
    );
};

export default memo(Timer);
