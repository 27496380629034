export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8"
            cy="8"
            r="6.5"
            stroke="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.793 7.5L8.1466 4.85355C7.95133 4.65829 7.95133 4.34171 8.1466 4.14645C8.34186 3.95118 8.65844 3.95118 8.8537 4.14645L11.6466 6.93934C12.2324 7.52513 12.2324 8.47487 11.6466 9.06066L8.8537 11.8536C8.65844 12.0488 8.34186 12.0488 8.1466 11.8536C7.95133 11.6583 7.95133 11.3417 8.1466 11.1464L10.793 8.5H4.00009V7.5H10.793Z"
            fill="white"
        />
    </svg>
);
