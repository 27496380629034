import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import SubscriptionsService from 'src/services/SubscriptionsService';

import UserStore from './user';

import type { CurrentSubscription, Subscription } from 'src/types/Subscription';

type SubscriptionsStore = {
    subscriptions: Subscription[];
    selectedPlan: number;
    promoSale: number;
    cancelSubscription: () => Promise<unknown>;
    setPromoSale: (value: number) => void;
    setSelectedPlan: (plan: number) => void;
    setSubscriptions: (subscriptions: Subscription[]) => void;
    clear: () => void;
};

const useSubscriptionsStore = create<SubscriptionsStore>(
    (set, _get) => ({
        subscriptions: [],
        selectedPlan: 0,
        promoSale: 0,
        clear: () => set({
            subscriptions: [],
            selectedPlan: 0,
            promoSale: 0,
        }),
        cancelSubscription: async () => {
            try {
                await SubscriptionsService.cancelSubscription();

                const { updateSubscription, current_subscription } = UserStore.getState();

                const updatedData = {
                    ...current_subscription,
                    is_recurring: false,
                };
                updateSubscription(updatedData as CurrentSubscription);

                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        setPromoSale: (value) => set({ promoSale: value }),

        setSelectedPlan: (plan) => set({ selectedPlan: plan }),

        setSubscriptions: (subscriptions) => {
            set({ subscriptions });
        },
    }),
    shallow
);

export default useSubscriptionsStore;
