import { Box, Collapse, Typography, styled } from '@mui/material';
import ChevroneUp from 'src/icons/ChevroneUp';
import useAppStore from 'src/stores/app';

import ItemMenu from './ItemMenu';

import type { ReactNode } from 'react';

export const AccordionMenuContainer = styled(Box)(() => ({
    border: '1px solid #E3ECF3',
    borderRadius: '16px',
    padding: '8px',
    opacity: 1,
    '& > div > div > div > div': {
        borderBottom: '1px solid #E3ECF3',
    },
    '& > div > div > div > div:last-child': {
        borderBottom: 'none',
    },
}));

export const AccordionButton = ({ isOpen = false }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap="8px"
            style={{
                cursor: 'pointer',
            }}
            height={32}
        >
            <Typography
                variant="body2"
                color="secondary"
            >
                {isOpen ? 'Скрыть' : 'Показать'}
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={24}
                width={24}
                borderRadius="50%"
                bgcolor={isOpen ? 'background.paper' : 'background.default'}
                style={{
                    transform: !isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transformOrigin: 'center',
                }}
            >
                <ChevroneUp />
            </Box>
        </Box>
    );
};

export type AccordionMenuItem = {
    title: string;
    icon: ReactNode;
    customActionIcon?: ReactNode;
    onClick: () => void;
};

type AccordionMenuProps = {
    items: AccordionMenuItem[];
    isMobile: boolean;
};

const AccordionMenu = ({ items = [], isMobile }: AccordionMenuProps) => {
    const { accordionMenu, setAccordionMenu } = useAppStore(({ accordionMenu, setAccordionMenu }) => ({
        accordionMenu,
        setAccordionMenu,
    }));

    const handleToggleMenu = () => {
        setAccordionMenu(!accordionMenu);
    };

    const getBackgroundColor = () => {
        if (accordionMenu && isMobile) {
            return 'background.default';
        }

        if (!accordionMenu && isMobile) {
            return 'background.paper';
        }

        return 'background.default';
    };

    return (
        <AccordionMenuContainer bgcolor={getBackgroundColor()}>
            <Collapse
                collapsedSize={'48px'}
                in={isMobile ? accordionMenu : true}
            >
                {isMobile && (
                    <ItemMenu
                        title="Ещё настройки"
                        onAction={handleToggleMenu}
                        customActionComponent={<AccordionButton isOpen={accordionMenu} />}
                    />
                )}
                {items.map((item) => {
                    return (
                        <ItemMenu
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            onAction={item.onClick}
                            customActionComponent={item.customActionIcon}
                        />
                    );
                })}
            </Collapse>
        </AccordionMenuContainer>
    );
};

export default AccordionMenu;
