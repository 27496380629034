function formatNumber(num: any) {
    const _num = +num;
    if (isNaN(_num) || num === undefined || num === null) {
      return '0';
    }
    const integerPart = Math.trunc(_num);
    const formattedNumber = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
    return formattedNumber;
}

export default formatNumber;