import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface AIFilterContextType {
    aiFilter: boolean;
    setAiFilter: (value: boolean) => void;
}

const AIFilterContext = createContext<AIFilterContextType | undefined>(undefined);

export const AIFilterProvider = ({ children }: { children: ReactNode }) => {
    const [aiFilter, setAiFilterState] = useState<boolean>(() => {
        const storedValue = localStorage.getItem('ai_filter');
        return storedValue === 'true';
    });

    useEffect(() => {
        localStorage.setItem('ai_filter', aiFilter.toString());
    }, [aiFilter]);

    const setAiFilter = (value: boolean) => {
        setAiFilterState(value);
    };

    return <AIFilterContext.Provider value={{ aiFilter, setAiFilter }}>{children}</AIFilterContext.Provider>;
};

export const useAIFilter = (): AIFilterContextType => {
    const context = useContext(AIFilterContext);
    if (context === undefined) {
        throw new Error('useAIFilter must be used within an AIFilterProvider');
    }
    return context;
};
