import { useEffect, useState } from 'react';
import { Box, Chip, Collapse } from '@mui/material';

import Geopoint from 'src/icons/Geopoint';
import ChevroneUpAndDows from 'src/icons/ChevroneUpAndDows';
import ChevroneUp from 'src/icons/ChevroneUp';
import Cross from 'src/icons/Cross';
import useSearchParams from 'src/hooks/useSearchParams';
import { openBottomSheet } from 'src/components/BottomSheet';

import SearchCityWidget from '../SearchCity';

type FilterItem = {
    id: number;
    name: string;
    selected: boolean;
};

type CollectionsFilterProps = {
    cities: string[];
    filters?: Array<FilterItem>;
    isDisabled?: boolean;
};

export default function CollectionsFilter({ cities = [], filters = [], isDisabled }: CollectionsFilterProps) {
    const {
        params: { city },
        setParams,
    } = useSearchParams();

    const [open, setIsOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState(city);

    const hasCityVariants = isDisabled ? false : cities.length > 1;

    const hasOnlyOneCity = cities.length === 1;

    useEffect(() => {
        if (selectedCity) {
            setParams({ city: selectedCity });
        }
    }, [selectedCity]);

    const handleOpenCityList = hasCityVariants
        ? () => {
              openBottomSheet(
                  <SearchCityWidget
                      cities={cities}
                      onSubmit={onCitySelectHandler}
                      activeCity={city}
                  />
              );
          }
        : undefined;

    const handleOpenAccordion = () => {
        setIsOpen(true);
    };

    const handleFilterClick = (id: number) => () => {
        console.log(id);
    };

    const onCitySelectHandler = (city: string) => {
        setSelectedCity(city);
    };

    const renderCollapseButton = () => {
        if (filters.length < 4) return null;

        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={32}
                height={32}
                border="1px solid #E3ECF3"
                borderRadius="50%"
                sx={{
                    cursor: 'pointer',
                }}
                onClick={handleOpenAccordion}
            >
                <Box
                    style={{
                        transform: open ? 'none' : 'rotate(180deg)',
                        transformOrigin: 'center',
                    }}
                >
                    <ChevroneUp />
                </Box>
            </Box>
        );
    };

    if (cities.length < 1) {
        return null;
    }

    return (
        <Collapse
            in={open}
            collapsedSize={'fit-content'}
        >
            <Box
                display="flex"
                gap={1}
            >
                <Chip
                    label={selectedCity || (hasOnlyOneCity ? cities[0] : 'Локация')}
                    clickable={!hasOnlyOneCity}
                    variant="outlined"
                    deleteIcon={
                        <Box>
                            <ChevroneUpAndDows />
                        </Box>
                    }
                    sx={{
                        background: '#fff',
                    }}
                    onClick={handleOpenCityList}
                    onDelete={handleOpenCityList}
                    icon={
                        <Box
                            pl={1}
                            mr={-1}
                        >
                            <Geopoint />
                        </Box>
                    }
                />

                {filters.map((filter) => {
                    const addProps = {} as any;

                    if (filter.selected) {
                        addProps.deleteIcon = (
                            <Box
                                width={16}
                                height={16}
                                borderRadius={'50%'}
                                bgcolor="background.default"
                                p={0.5}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Cross />
                            </Box>
                        );
                        addProps.onDelete = () => {};
                    }

                    return (
                        <Chip
                            key={filter.id}
                            label={filter.name}
                            variant={filter.selected ? 'filled' : 'outlined'}
                            color={filter.selected ? 'primary' : 'default'}
                            onClick={handleFilterClick(filter.id)}
                            clickable
                            {...addProps}
                        />
                    );
                })}
                {renderCollapseButton()}
            </Box>
        </Collapse>
    );
}
