import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import CreateProject, { ICreateProjectProps } from './Content';

export default function CreateProjectScreen(props: ICreateProjectProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showBack>
                <CreateProject {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout withMainContentWrapper>
            <CreateProject {...props} />
        </DesktopLayout>
    );
}
