import { getProjects, updateProject, createProject } from './agent';

import type { TUpdateTProject } from 'src/types/Projects';

class ProjectsService {
    queryKey = 'projects';

    getProjects = async () => {
        try {
            const { data } = await getProjects();

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    createProject = async (project: TUpdateTProject) => {
        try {
            const { data } = await createProject(project);

            return Promise.resolve(data);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    updateProject = async (project: TUpdateTProject) => {
        try {
            const { data } = await updateProject(project);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new ProjectsService();
