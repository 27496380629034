import { Box, Typography } from '@mui/material';

import useIsMobileSize from 'src/hooks/useIsMobileSize';
import Steps from 'src/widgets/Steps';
import { LayoutPaper, DesktopLayout } from 'src/layout';

export default function BeginWorkScreen() {
    const isMobile = useIsMobileSize();

    const renderContent = () => {
        return (
            <>
                <Box mb={20 / 8}>
                    <Typography
                        variant="h1"
                        color="primary"
                    >
                        Начало работы
                    </Typography>
                </Box>
                <Steps />
            </>
        );
    };

    if (!isMobile) return <DesktopLayout>{renderContent()}</DesktopLayout>;

    return <LayoutPaper isWhite>{renderContent()}</LayoutPaper>;
}
