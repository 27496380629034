import { useHistory } from 'react-router-dom';
import qs from 'qs';

export default function useGoTo() {
    const history = useHistory();
    const getQueryParams = (params?: unknown) => {
        if (!params) return '';

        return qs.stringify(params, { skipNulls: true });
    };

    const goTo = (path: string, params?: unknown, state?: unknown) => {
        history.push({
            pathname: path,
            search: getQueryParams(params),
            state,
        });
    };

    return goTo;
}
