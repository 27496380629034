import { Box } from '@mui/material';
import AIFilter from 'src/components/AIFilter';

export default function AISheet() {
    return (
        <Box
            p={4}
            sx={{ maxWidth: '300px', margin: '0 auto' }}
        >
            <AIFilter />
        </Box>
    );
}
