import { Box, Button, FormControl, RadioGroup } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import PaymentScheme, { type TypePaymentScheme } from 'src/validations/payment';
import SubscriptionItem from 'src/widgets/SubscriptionItem';
import useSubscriptionsStore from 'src/stores/subscriptions';
import getPeriodsInfo from 'src/utils/getPeriodInfo';
import useGoTo from 'src/hooks/useGoTo';
import { ROUTES_URL } from 'src/constants/routes';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

export default function SubscriptionsForm() {
    const isMobile = useIsMobileSize();
    const goTo = useGoTo();

    const { subscriptions, selectedPlan, setSelectedPlan } = useSubscriptionsStore(
        ({ subscriptions, selectedPlan, setSelectedPlan }) => ({ subscriptions, selectedPlan, setSelectedPlan })
    );

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<TypePaymentScheme>({
        defaultValues: {
            plan_id: selectedPlan > 0 ? selectedPlan : 0,
        },
        resolver: zodResolver(PaymentScheme),
    });

    const onSubmit: SubmitHandler<TypePaymentScheme> = (data) => {
        setSelectedPlan(+data.plan_id);
        goTo(ROUTES_URL.PAYMENT);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
            >
                <FormControl fullWidth>
                    <Controller
                        name="plan_id"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                onChange={(event) => {
                                    field.onChange(+event.target.value);
                                }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                }}
                            >
                                {subscriptions
                                    .sort((a, b) => {
                                        if (+a.price < +b.price) return -1;
                                        if (+a.price > +b.price) return 1;

                                        return 0;
                                    })
                                    .map((subscription) => (
                                        <SubscriptionItem
                                            key={subscription.id}
                                            value={subscription.id}
                                            title={subscription.name}
                                            price={getPeriodsInfo(+subscription.price)[1].priceWithSale}
                                            clients={subscription.potential_clients_per_month}
                                            color={'#' + subscription.color_code}
                                            bundles={subscription.bundles_limit}
                                            chats={subscription.chats_limit}
                                            keywords={subscription.keywords_limit}
                                            checkedValue={field.value}
                                        />
                                    ))}
                            </RadioGroup>
                        )}
                    />
                </FormControl>
            </Box>
            <Box
                display="flex"
                alignItems="flex-end"
                mb={1}
                mt={1}
                flexGrow={1}
                sx={{
                    position: isMobile ? 'sticky' : 'static',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: isMobile ? 'white' : 'transparent',
                    padding: isMobile ? '16px' : '0',
                    zIndex: 10,
                }}
            >
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={!isValid}
                    fullWidth
                >
                    Перейти к оплате
                </Button>
            </Box>
        </form>
    );
}
