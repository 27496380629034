import { Box, Button, Typography } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PasswordField from 'src/components/PasswordField';
import WidgetWrapper from 'src/components/FormWrapper';
import CustomAccordion from 'src/components/CustomAccordion';
import scheme, { type TypeCreatePasswordScheme } from 'src/validations/createPassword';
import useResetPasswordStore from 'src/stores/restorePassword';
import useGoTo from 'src/hooks/useGoTo';

const CreatePasswordForm = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TypeCreatePasswordScheme>({
        resolver: zodResolver(scheme),
    });
    const goTo = useGoTo();
    const resetPasswordStore = useResetPasswordStore();

    const onSubmit: SubmitHandler<TypeCreatePasswordScheme> = async (data) => {
        try {
            await resetPasswordStore.confirmResetPassword(data);
            goTo('/');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <WidgetWrapper
            name="Создание пароля"
            topGutter
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={2}>
                    <Controller
                        name="new_password"
                        control={control}
                        render={({ field }) => (
                            <PasswordField
                                {...field}
                                label="Придумайте пароль"
                                placeholder="Новый пароль"
                                error={errors?.new_password?.message}
                            />
                        )}
                    />
                </Box>
                <Box mb={2}>
                    <Controller
                        name="new_password2"
                        control={control}
                        render={({ field }) => (
                            <PasswordField
                                {...field}
                                placeholder="Повторите пароль"
                                error={errors?.new_password2?.message}
                            />
                        )}
                    />
                </Box>
                <Box mb={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Далее
                    </Button>
                </Box>
                <Box mb={0.5}>
                    <CustomAccordion title="Как создать пароль">
                        <Box>
                            <Typography
                                variant="body2"
                                color="primary"
                            >
                                Для создания пароля разрешается применять следующие символы:
                            </Typography>
                            <ul
                                style={{
                                    listStyle: 'outside',
                                }}
                            >
                                <li>
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        Заглавные латинские буквы: от A до Z (26 символов)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        Строчные латинские буквы: от a до z (26 символов)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        Цифры от 0 до 9 (10 символов)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        {
                                            'Символы: (пробел) ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [  ] ^ _` { | } ~ (33 символа)'
                                        }
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </CustomAccordion>
                </Box>
            </form>
        </WidgetWrapper>
    );
};

export default CreatePasswordForm;
