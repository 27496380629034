import PublicLayout from 'src/layout/PublicLayout';
import { LayoutPaper } from 'src/layout';
import CreatePasswordForm from 'src/widgets/CreatePasswordForm';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import useSessionStore from 'src/stores/session';
import { DesktopLayout } from 'src/layout';

const CreatePasswordScene = () => {
    const { isAuthorized } = useSessionStore(({ isAuthorized }) => ({ isAuthorized }));
    const isMobile = useIsMobileSize();

    if (isAuthorized && !isMobile) {
        return (
            <DesktopLayout>
                <CreatePasswordForm />
            </DesktopLayout>
        );
    }

    return (
        <LayoutPaper>
            <PublicLayout>
                <CreatePasswordForm />
            </PublicLayout>
        </LayoutPaper>
    );
};

export default CreatePasswordScene;
