export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="black"
            style={{ mixBlendMode: 'exclusion' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="white"
            style={{ mixBlendMode: 'difference' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="white"
            style={{ mixBlendMode: 'saturation' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="white"
            style={{ mixBlendMode: 'overlay' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="black"
            style={{ mixBlendMode: 'overlay' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="white"
            style={{ mixBlendMode: 'overlay' }}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50013 4.5C8.50013 4.22386 8.27628 4 8.00013 4C7.72399 4 7.50013 4.22386 7.50013 4.5V7.66148L5.31444 8.53576C5.05805 8.63832 4.93334 8.9293 5.03589 9.1857C5.13845 9.44209 5.42944 9.56679 5.68583 9.46424L8.17501 8.46857C8.27299 8.43198 8.35642 8.36555 8.41422 8.28032C8.47186 8.19501 8.50255 8.09289 8.50013 7.98835V4.5Z"
            fill="black"
            style={{ mixBlendMode: 'overlay' }}
        />
    </svg>
);
