export const UsersIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5848_76659)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0002 17H17C17.9293 17 18.394 17 18.7804 17.0769C20.3671 17.3925 21.6075 18.6329 21.9232 20.2196C22 20.606 22 21.0707 22 22H21V21C21 19.3431 19.6569 18 18 18H16.0002C15.7159 17.6212 15.3791 17.2843 15.0002 17Z"
                    fill="#1F1F1F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 17H11C11.9293 17 12.394 17 12.7804 17.0769C14.3671 17.3925 15.6075 18.6329 15.9231 20.2196C16 20.606 16 21.0707 16 22H15V21C15 19.3431 13.6569 18 12 18H6C4.34315 18 3 19.3431 3 21V22H2C2 21.0707 2 20.606 2.07686 20.2196C2.39249 18.6329 3.63288 17.3925 5.21964 17.0769C5.60603 17 6.07069 17 7 17Z"
                    fill="#1F1F1F"
                />
                <circle
                    cx="18.5"
                    cy="11.5"
                    r="3"
                    stroke="#1F1F1F"
                />
                <circle
                    cx="9"
                    cy="8"
                    r="5.5"
                    stroke="#1F1F1F"
                />
            </g>
            <defs>
                <clipPath id="clip0_5848_76659">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
