import { Box, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';

import AddItemField from 'src/widgets/AddItemField';
import TitleWithCounter from 'src/widgets/TitleWithCounter';
import ListEntities from 'src/widgets/ListEntities';
import Book from 'src/icons/Book';
import OpenedBook from 'src/icons/OpenedBook';
import useUserStore from 'src/stores/user';
import useProjectsStore from 'src/stores/projects';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import useKeywordStore from 'src/stores/keywords';
import KeywordsService from 'src/services/KeywordsService';
import { IKeyword } from 'src/types/Keywords';

const EmptyList = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={10}
        >
            <Box mb={1}>
                <OpenedBook />
            </Box>
            <Box mb={0.5}>
                <Typography
                    variant="body1"
                    color="primary"
                    textAlign="center"
                >
                    Добавьте слова
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    color="secondary"
                    textAlign="center"
                >
                    Формы склонения и синонимы будут добавленны автоматически, для вашего удобства
                </Typography>
            </Box>
        </Box>
    );
};

export interface IKeywordsProps {
    onSuccess?: () => void;
}

export interface IKeywordState {
    id: number | string;
    name: string;
}

const getKeywordsStructure = (keywords: IKeyword[]) => {
    return (
        keywords.map(({ id, word }) => ({
            id,
            name: word,
        })) || []
    );
};

export default function Keywords({ onSuccess }: IKeywordsProps) {
    const activeProject = useProjectsStore(({ activeProject }) => activeProject);
    const keywords = useKeywordStore(({ keywords }) => keywords);

    const [items, setItems] = useState<IKeywordState[]>(getKeywordsStructure(keywords));

    const { isLoading: isFetchKeywordsLoading, refetch } = useQuery(
        KeywordsService.queryKey,
        useKeywordStore.getState().fetchKeywords
    );

    useEffect(() => {
        setItems(() => getKeywordsStructure(keywords));
    }, [keywords]);

    const keywordsLimit = useUserStore(({ current_subscription }) => current_subscription?.plan.keywords_limit) || 0;

    const currentCount = items.length;

    const isMaxCount = currentCount >= keywordsLimit;

    const { mutate: updateProject, isLoading: isUpdateProjectLoading } = useMutation(
        useProjectsStore.getState().updateProject,
        {
            onSuccess: () => {
                onSuccess?.();
                refetch();
                enqueueSnackbar('Ключевые слова сохранены', { variant: 'success' });
            },
            onError: (error) => {
                console.log(error);
                enqueueSnackbar({ message: 'Ошибка сохранения ключевые слов', variant: 'error' });
            },
        }
    );

    const onAddKeyword = (value: string) => {
        setItems((prevState) => {
            if (prevState.some(({ name }) => name === value)) {
                enqueueSnackbar({ message: 'Ключевое слово уже добавлено!', variant: 'info' });
                return prevState;
            }
            return [...prevState, { name: value, id: (Number(prevState[prevState.length - 1]?.id) || 0) + 1 }];
        });
    };

    const onDeleteKeyword = (chat: IKeywordState) => {
        setItems((prevState) => prevState.filter(({ id }) => id !== chat.id));
    };

    const onSaveHandler = () => {
        updateProject({
            id: activeProject?.id,
            keywords: items.map(({ name }) => name),
        });
    };

    return (
        <LoadingWrapper
            isLoading={isUpdateProjectLoading || isFetchKeywordsLoading}
            isForced={isFetchKeywordsLoading}
        >
            <Box
                pt={2}
                minHeight="100%"
                display="flex"
                flexDirection="column"
            >
                <Box mb={1.5}>
                    <TitleWithCounter
                        title="Ключевые слова"
                        current={currentCount}
                        total={keywordsLimit}
                    />
                </Box>
                <Box
                    maxWidth="80%"
                    mb={20 / 8}
                >
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        Добавляйте ключевые слова и фразы, по которым клиенты могут искать ваши продукты или услуги
                    </Typography>
                </Box>
                <Box mb={3}>
                    <AddItemField
                        icon={<Book />}
                        placeholder="Добавьте ключевое слово"
                        onAddItem={onAddKeyword}
                        disabled={isMaxCount}
                    />
                </Box>
                {!currentCount ? (
                    <EmptyList />
                ) : (
                    <ListEntities
                        entities={items}
                        // getSecondaryText={(entity) => entity.synonims}
                        onDeleteItem={onDeleteKeyword}
                    />
                )}
                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexGrow={1}
                    pb={1}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!currentCount}
                        fullWidth
                        onClick={onSaveHandler}
                    >
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </LoadingWrapper>
    );
}
