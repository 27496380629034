export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8"
            cy="8"
            r="7"
            fill="#E3ECF3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.70046 5.75036C6.96058 5.30071 7.44551 5 8.0001 5C8.82852 5 9.5001 5.67157 9.5001 6.5C9.5001 7.19962 9.02066 7.78853 8.37168 7.95374C7.95739 8.05921 7.5001 8.42613 7.5001 9C7.5001 9.27614 7.72395 9.5 8.0001 9.5C8.27604 9.5 8.49977 9.27647 8.5001 9.0006L8.50031 9.00007C8.50121 8.99793 8.50461 8.99107 8.5139 8.98105C8.53427 8.95907 8.57142 8.93479 8.61839 8.92283C9.69978 8.64753 10.5001 7.66786 10.5001 6.5C10.5001 5.11929 9.38081 4 8.0001 4C7.07411 4 6.26637 4.50369 5.83486 5.24964C5.69659 5.48867 5.77827 5.79453 6.0173 5.9328C6.25633 6.07107 6.56219 5.98939 6.70046 5.75036ZM8.00008 10.5C7.58587 10.5 7.25008 10.8358 7.25008 11.25C7.25008 11.6642 7.58587 12 8.00008 12C8.4143 12 8.75008 11.6642 8.75008 11.25C8.75008 10.8358 8.4143 10.5 8.00008 10.5Z"
            fill="black"
        />
    </svg>
);
