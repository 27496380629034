import { Box, Typography, styled } from '@mui/material';

const ListBox = styled(Box)(() => ({
    border: '1px solid',
    borderColor: '#E3ECF3',
    borderRadius: 16,
    width: '100%',
}));

const ListBoxItem = styled(Box)(() => ({
    padding: '8px 16px',
    borderBottom: '1px solid #E3ECF3',
    '&:last-child': {
        borderBottom: 'none',
    },
}));

const SimpleList = ({ list = [] as any, title = '' }) => {
    return (
        <Box>
            {title && (
                <Box mb={1}>
                    <Typography
                        fontWeight={500}
                        variant="body1"
                        color="secondary"
                    >
                        {title}
                    </Typography>
                </Box>
            )}
            <ListBox>
                {list.map((item: any, idx: number) => {
                    return (
                        <ListBoxItem key={idx}>
                            <Typography
                                variant="body1"
                                color="secondary"
                            >
                                {item}
                            </Typography>
                        </ListBoxItem>
                    );
                })}
            </ListBox>
        </Box>
    );
};

export default SimpleList;
