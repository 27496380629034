import { enqueueSnackbar } from 'notistack';
import { useEffect, useLayoutEffect } from 'react';
import { useMutation } from 'react-query';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

import EventService from 'src/services/EventService';
import SessionService from 'src/services/SessionService';
import useUserStore from 'src/stores/user';

import { getBeginWorkNextStepId, getBeginWorkStepByOrder, getBeginWorkStepOrder } from 'src/utils/beginWork';

import useGoTo from 'src/hooks/useGoTo';

import { history } from 'src/utils/history';

import type { StepsType } from 'src/types/User';

export const UPDATE_BEGIN_WORK_STEP_EVENT_TYPE = 'updateBeginWorkStep';

export const updateBeginWorkStep = (params: IUpdateBeginWorkStepEvent) => {
    EventService.dispatch(UPDATE_BEGIN_WORK_STEP_EVENT_TYPE, params);
};

interface IUpdateBeginWorkStepsProps {
    children: React.ReactNode;
}

interface IUpdateBeginWorkStepEvent {
    currentStep: StepsType;
    decrease?: boolean;
    onSuccess?: () => void | Promise<void>;
}

export default function UpdateBeginWorkStepsProvider({ children }: IUpdateBeginWorkStepsProps) {
    const goTo = useGoTo();

    const { updateStep: updateOnboardingStep, isOnboardingCompleted } = useUserStore.getState();

    const { mutateAsync: updateStep, isLoading } = useMutation(SessionService.queryKey, updateOnboardingStep, {
        onError: (error) => {
            console.info(error);
            enqueueSnackbar('Ошибка обновления онбоардинга', { variant: 'error' });
        },
    });

    useEffect(() => {
        if (isOnboardingCompleted) {
            return;
        }

        EventService.subscribe(
            UPDATE_BEGIN_WORK_STEP_EVENT_TYPE,
            async (_type, { currentStep, decrease, onSuccess }: IUpdateBeginWorkStepEvent) => {
                const nextStep = getBeginWorkNextStepId(currentStep);

                if (nextStep) {
                    const currentOrder = useUserStore.getState().onboardingStep;
                    const nextOrder = getBeginWorkStepOrder(nextStep);

                    if (decrease || nextOrder > currentOrder) {
                        try {
                            await updateStep(nextStep);
                            await onSuccess?.();
                        } catch (error) {
                            console.info(error);
                        }
                    }
                }
            }
        );
    }, [isOnboardingCompleted]);

    useLayoutEffect(() => {
        if (isOnboardingCompleted) {
            return;
        }
        const stepTypeByOrder = getBeginWorkStepByOrder(useUserStore.getState().onboardingStep);

        if (stepTypeByOrder?.type === 'page' && stepTypeByOrder.link) {
            goTo(stepTypeByOrder.link);
        }
    }, [isOnboardingCompleted]);

    return <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>;
}
